import { Box } from "@mui/material";
import React, { useEffect } from 'react';
import AccessDenied from "./Auth/AccessDenied";
import { signOut } from "./Auth/AuthComponent";
import MainContent from "./MainContent/MainContent";
import AppToolbar from "./Navigation/AppToolbar";
import Sidebar, { DRAWER_WIDTH } from "./Navigation/Sidebar";

import { SupabaseClient } from '@supabase/supabase-js';
import { useNavigate } from 'react-router-dom';
import { updateUserFromSession } from '../services/subscriptionService';
import { AuthSessionType, ConsultationSessionType, User, UserInfo } from "../types";


interface AppComponentProps {
    authSession: AuthSessionType | null;
    setAuthSession: (session: AuthSessionType | null) => void;
    user: User;
    setUser: (user: User) => void;
    consultationSession: ConsultationSessionType | null;
    setConsultationSession: (session: ConsultationSessionType | null) => void;
    consultationSessions: { [key: string]: ConsultationSessionType };
    setConsultationSessions: (sessions: { [key: string]: ConsultationSessionType }) => void;
    templateSelected: string;
    transcriptionLanguage: string;
    noteLanguage: string;
    userInfo: UserInfo;
    setUserInfo: (userInfo: UserInfo) => void;
    setTranscriptionLanguage: (language: string) => void;
    setNoteLanguage: (language: string) => void;
    setTemplateSelected: (template: string) => void;
    drawerOpen: boolean;
    setDrawerOpen: (open: boolean) => void;
    isDesktop: Boolean;
    supabase: SupabaseClient;
}


const AppComponent: React.FC<AppComponentProps> = ({
    authSession,
    setAuthSession,
    user,
    setUser,
    consultationSession,
    setConsultationSession,
    consultationSessions,
    setConsultationSessions,
    templateSelected,
    transcriptionLanguage,
    noteLanguage,
    userInfo,
    setUserInfo,
    setTranscriptionLanguage,
    setNoteLanguage,
    setTemplateSelected,
    drawerOpen,
    setDrawerOpen,
    isDesktop,
    supabase
}) => {

    const navigate = useNavigate();
    
    useEffect(() => {
        const session = supabase.auth.getSession()
        session.then((session) => {
            if (!session.data.session) {
                navigate('/sign-in');
            }
            else {
                updateUserFromSession(session.data.session, user, setUser);
            }
        })
    }, [authSession, navigate]);

    useEffect(() => {
        if (userInfo.account.id) {
            !userInfo.account.speciality_id && navigate("/complete-registration");
        }
    }, [userInfo]);
    
    return (
        user.activeSubscription === false ? (
            <AccessDenied />
        ) : (
            <div>
                <Box component="nav">
                    <AppToolbar drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} isDesktop={isDesktop} />
                </Box>
                <Box component="nav" mt={{ xs: "56px", md: 0 }}>
                    <Sidebar
                        onSignOut={() => signOut(setAuthSession, supabase)}
                        consultationSession={consultationSession}
                        setConsultationSession={setConsultationSession}
                        consultationSessions={consultationSessions}
                        setConsultationSessions={setConsultationSessions}
                        drawerOpen={drawerOpen}
                        setDrawerOpen={setDrawerOpen}
                        isDesktop={isDesktop}
                    />
                </Box>
                <Box component="main" sx={{ ml: {sx: 0, md: DRAWER_WIDTH} }}>
                    <MainContent
                        consultationSession={consultationSession}
                        setConsultationSession={setConsultationSession}
                        templateSelected={templateSelected}
                        isDesktop={isDesktop}
                        user={user}
                        transcriptionLanguage={transcriptionLanguage}
                        noteLanguage={noteLanguage}
                        userInfo={userInfo}
                        setUserInfo={setUserInfo}
                        setTranscriptionLanguage={setTranscriptionLanguage}
                        setNoteLanguage={setNoteLanguage}
                        setTemplateSelected={setTemplateSelected}
                    />
                </Box>
            </div>
        )
    );
};

export default AppComponent;
