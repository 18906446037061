import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import espanol from "./copywriting/espanol.json";
import english from "./copywriting/english.json";
import catalan from "./copywriting/catalan.json";
import portugues from "./copywriting/portugues.json"

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  es: {
    translation: espanol
  },
  pt: {
    translation: portugues
  },
  en: {
    translation: english
  },
  ca: {
    translation: catalan
  }
};

i18n
  .use(LanguageDetector) // add language detector
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "es", // fallback language if the detected language is not available
    interpolation: {
      escapeValue: false // react already protects from xss
    }
  });

export default i18n;
