import { createClient, SupabaseClient } from "@supabase/supabase-js";

const supabaseUrl: string = process.env.REACT_APP_SUPABASE_PUBLIC_URL!;
const supabaseAnonKey: string = process.env.REACT_APP_SUPABASE_PUBLIC_ANON_KEY!;

export const supabase: SupabaseClient  = createClient(supabaseUrl, supabaseAnonKey);

export const getSupabaseAccessToken = async (): Promise<string | null> => {
    const session = (await supabase.auth.getSession()).data.session;
    return session?.access_token || null;
};

export const logout = async (): Promise<void> => {
    const { error } = await supabase.auth.signOut();
    if (error) throw new Error(error.message);
};
