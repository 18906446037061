import React, { useEffect, useState } from "react";

import { Alert, AlertColor, Snackbar } from "@mui/material";

interface SnackbarNotificationProps {
  triggerOpen: boolean;
  message: String;
  color?: string;
  time?: number;
}

const SnackbarNotification: React.FC<SnackbarNotificationProps> = ({
  triggerOpen,
  message,
  color = "success",
  time = 4000,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  
  const openSnackbar = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, time);
  };
  
  useEffect(() => {
    if (triggerOpen && !open) {
      openSnackbar();
    }
  }, [triggerOpen]);
 
  return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        message={message}
      >
        <Alert
          color={color as AlertColor}
        >
          { message }
        </Alert>
      </Snackbar>
  );
};

export default SnackbarNotification;

