import { Box, Link } from "@mui/material";
import React, { useEffect } from "react";
import accessDeniedAnimation from "./assets/access-denied-animation.json";

import Typography from '@mui/material/Typography';
import Lottie from "lottie-react";
import { useTranslation } from 'react-i18next';

const PRICING_PAGE_URL = "https://www.troper.io/#precios"
const LOCAL_STORAGE_SESSION_KEY = "sb-mhvahfhiifnongzgqpls-auth-token"

const AccessDenied: React.FC<{}> = ({}) => {
    const { t } = useTranslation();

    useEffect(() => {
        const timer = setTimeout(() => {
            localStorage.removeItem(LOCAL_STORAGE_SESSION_KEY);
            window.location.href = PRICING_PAGE_URL;
        }, 5000); 
    
        return () => clearTimeout(timer); 
      }, []); 
    
    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            justifyContent="center" 
            sx={{ mt: 20 }}
        >
            <Typography variant="h6" sx={{ mb: 5 }}>
                {t('access_denied')}
            </Typography>
            
            <Lottie animationData={accessDeniedAnimation} />

            <Typography variant="body1" sx={{ mt: 5 }}>
                {t('no_active_subscription')} <Link href={PRICING_PAGE_URL} color="inherit">{t('pricing_page')}</Link>
            </Typography>
        </Box>
    );
};

export default AccessDenied;
