import { FC, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { useMediaQuery } from "@mui/material";
import AppComponent from "./components/AppComponent";
import "./index.css";
import {
  loadConsultationSessions,
  storeConsultationSessionInLocalStorage,
} from "./services/sessionService";
import { updateUserFromSession } from "./services/subscriptionService";
import { AuthSessionType, ConsultationSessionType, User, UserInfo, defaultUser, defaultUserInfo } from "./types";

import { supabase } from "./api/supabaseClient";
import { CompleteRegistration } from "./components/Auth/CompleteRegistration";
import { ResetPassword } from "./components/Auth/ResetPassword";
import { SignIn } from "./components/Auth/SignIn";
import { SignUp } from "./components/Auth/SignUp";
import { UpdatePassword } from "./components/Auth/UpdatePassword";
import { DEFAULT_TEMPLTAE_ID, SettingsPage, settingsPages } from "./components/Settings/SettingsPage";
import { DEFAULT_TRANSCRIPTION_LANGUAGE } from "./transcriptionConfig";
import { fetchUserInfo } from "./services/userService";
import { useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";


const App: FC = () => {
  const { i18n } = useTranslation();
  const [authSession, setAuthSession] = useState<AuthSessionType | null>(null);
  const [consultationSession, setConsultationSession] =
    useState<ConsultationSessionType | null>(null);
  const [consultationSessions, setConsultationSessions] = useState<{
    [key: string]: ConsultationSessionType;
  }>(() => loadConsultationSessions());
  const [user, setUser] = useState<User>(defaultUser);
  const [userInfo, setUserInfo] = useState<UserInfo>(defaultUserInfo);
  const [transcriptionLanguage, setTranscriptionLanguage] = useState<string>(DEFAULT_TRANSCRIPTION_LANGUAGE);
  const [noteLanguage, setNoteLanguage] = useState<string>(DEFAULT_TRANSCRIPTION_LANGUAGE);
  const [templateSelected, setTemplateSelected] = useState<string>("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isDesktop = useMediaQuery("(min-width:900px)");

  useEffect(() => {
    if (consultationSession) {
      setConsultationSessions((currentSessions) => ({
        ...currentSessions,
        [consultationSession.session_id]: consultationSession,
      }));
      storeConsultationSessionInLocalStorage(consultationSession);
    }
  }, [consultationSession]);

  useEffect(() => {
    if (authSession) {
      updateUserFromSession(authSession, user, setUser);
    }
  }, [authSession]);

  useEffect(() => {
    const settingsResponse = supabase.from('settings').select();
    settingsResponse.then((settingsResponse) => {
      const settings = settingsResponse.data ? settingsResponse.data[0] : {};
      setTemplateSelected(settings?.preferred_template);
      setTranscriptionLanguage(settings?.transcription_language);
    }
  )}, []);

  useEffect(() => {
    const fetchUserInfoAsync = async () => {
      const userInfo = await fetchUserInfo();
      userInfo?.account?.id && setUserInfo(userInfo);
    };
    fetchUserInfoAsync();
  }, [authSession]);

  useEffect(() => {
    if (userInfo.account.id) {
      setTemplateSelected(userInfo.settings.preferred_template || DEFAULT_TEMPLTAE_ID);
      setTranscriptionLanguage(userInfo.settings.preferred_language || DEFAULT_TRANSCRIPTION_LANGUAGE);
      setNoteLanguage(userInfo.settings.preferred_note_language || DEFAULT_TRANSCRIPTION_LANGUAGE);
      i18n.changeLanguage(userInfo.settings.application_language || DEFAULT_TRANSCRIPTION_LANGUAGE);
    }
  }
  , [userInfo]);

  const posthog = usePostHog()

  useEffect(() => {
      if (userInfo.account.id) {
          posthog?.identify(userInfo.account.id, {
              email: userInfo.account.email,
          })
      }
  }, [posthog, userInfo])

  return (
    <BrowserRouter>
      <Routes>
          <Route
            path="/"
            element={
              <AppComponent
                authSession={authSession}
                setAuthSession={setAuthSession}
                user={user}
                setUser={setUser}
                consultationSession={consultationSession}
                setConsultationSession={setConsultationSession}
                consultationSessions={consultationSessions}
                setConsultationSessions={setConsultationSessions}
                templateSelected={templateSelected}
                transcriptionLanguage={transcriptionLanguage}
                noteLanguage={noteLanguage}
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                setTranscriptionLanguage={setTranscriptionLanguage}
                setNoteLanguage={setNoteLanguage}
                setTemplateSelected={setTemplateSelected}
                drawerOpen={isDesktop ? true : drawerOpen}
                setDrawerOpen={setDrawerOpen}
                isDesktop={isDesktop}
                supabase={supabase}
              />
            }
          />
        <Route path="/sign-in" element={<SignIn onSession={setAuthSession} supabase={supabase} />} />
        <Route path="/sign-up" element={<SignUp onSession={setAuthSession} supabase={supabase} />} />
        <Route path="/reset-password" element={<ResetPassword onSession={setAuthSession} supabase={supabase} />} />
        <Route path="/update-password" element={<UpdatePassword onSession={setAuthSession} supabase={supabase} />} />
        <Route path="/complete-registration" element={<CompleteRegistration onSession={setAuthSession} supabase={supabase} setUserInfo={setUserInfo} />} />
        
        <Route path="/settings" element={<SettingsPage isDesktop={isDesktop} supabase={supabase} setTemplateSelected={setTemplateSelected} setTranscriptionLanguage={setTranscriptionLanguage} setNoteLanguage={setNoteLanguage} userInfo={userInfo} setUserInfo={setUserInfo}/>} />
        <Route path={"/settings/"+settingsPages.ACCOUNT.id} element={<SettingsPage defaultPage={settingsPages.ACCOUNT} isDesktop={isDesktop} supabase={supabase} setTemplateSelected={setTemplateSelected} setTranscriptionLanguage={setTranscriptionLanguage} setNoteLanguage={setNoteLanguage} userInfo={userInfo} setUserInfo={setUserInfo}/>} />
        <Route path={"/settings/"+settingsPages.NOTES.id} element={<SettingsPage defaultPage={settingsPages.NOTES} isDesktop={isDesktop} supabase={supabase} setTemplateSelected={setTemplateSelected} setTranscriptionLanguage={setTranscriptionLanguage} setNoteLanguage={setNoteLanguage} userInfo={userInfo} setUserInfo={setUserInfo}/>} />
        <Route path={"/settings/"+settingsPages.SUBSCRIPTION.id} element={<SettingsPage defaultPage={settingsPages.SUBSCRIPTION} isDesktop={isDesktop} supabase={supabase} setTemplateSelected={setTemplateSelected} setTranscriptionLanguage={setTranscriptionLanguage} setNoteLanguage={setNoteLanguage}  userInfo={userInfo} setUserInfo={setUserInfo}/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
