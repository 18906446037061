import { Box, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  createNewSession,
  deleteConsultationSessionFromLocalStorage,
  storeConsultationSessionInLocalStorage,
} from "../../services/sessionService";
import { ConsultationSession } from "../../types";
import "./Sidebar.css";

import AddIcon from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import Button from '@mui/material/Button';

import { Drawer, useMediaQuery, useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import ConsultationItem from "./ConsultationItem";

import { CancelOutlined, Delete } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { AlertDialog } from "../Settings/AlertDialog";
import SnackbarNotification from "./Tools/SnackbarNotification";
import { useTranslation } from 'react-i18next'; // Importing useTranslation hook

export const DRAWER_WIDTH = "280px";

interface SidebarProps {
  onSignOut: () => void;
  consultationSession: ConsultationSession | null;
  setConsultationSession: (session: ConsultationSession | null) => void;
  consultationSessions: Record<string, ConsultationSession>;
  setConsultationSessions: (sessions: Record<string, ConsultationSession>) => void;
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  isDesktop: Boolean;
}

const Sidebar: React.FC<SidebarProps> = ({
  onSignOut,
  consultationSession,
  setConsultationSession,
  consultationSessions,
  setConsultationSessions,
  drawerOpen,
  setDrawerOpen,
  isDesktop,
}) => {
  const { t } = useTranslation(); // Initializing translation hook
  const [consultationSessionId, setConsultationSessionId] = useState<string | null>(null);
  const [consultationSessionDeleted, setConsultationSessionDeleted] = useState<boolean>(false);
  const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false);
  const [deleteSessionId, setDeleteSessionId] = useState<string>("");

  const handleNewConsultation = async () => {
    if (consultationSession) {
      storeConsultationSessionInLocalStorage(consultationSession);
    }
    const newConsultationSession: ConsultationSession = await createNewSession();
    setConsultationSession(newConsultationSession);
    cleanUnusedSessions();
  };

  const handleDeleteSession = (sessionId: string) => {
    setDeleteSessionId(sessionId);
    setShowAlertDialog(true);
  };

  const cleanUnusedSessions = async () => {
    const sessions = { ...consultationSessions };
    if (sessions) {
      const filteredSessionsEntries = Object.entries(sessions).filter(([key, value]) => 
        value.title !== "" 
        || value.transcription.length !== 0
        || (value.clinicalNoteSections && value.clinicalNoteSections.length !== 0)
      );
      const formattedSessions = Object.fromEntries(filteredSessionsEntries);
      setConsultationSessions(Object.fromEntries(filteredSessionsEntries));
      localStorage.setItem("consultationSessions", JSON.stringify(formattedSessions));
    }
  };

  useEffect(() => {
    if (!consultationSession) {
      createNewSession().then((newConsultationSession) => {
        setConsultationSession(newConsultationSession);
        setConsultationSessionId(newConsultationSession.session_id);
        cleanUnusedSessions();
      });
    }
  }, [consultationSession]);

  useEffect(() => {
    handleDrawerClose();
  }, [consultationSessions]);

  const groupSessionsByDate = (sessions: Record<string, ConsultationSession>) => {
    const groupedSessions: Record<string, ConsultationSession[]> = {};
    Object.entries(sessions).forEach(([sessionId, session]) => {
      const date = new Date(session.date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
      if (!groupedSessions[date]) {
        groupedSessions[date] = [];
      }
      groupedSessions[date].push(session);
    });
    return groupedSessions;
  };

  const groupedSessions = groupSessionsByDate(consultationSessions);

  const backgroundColor = grey[100];

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const navigate = useNavigate();
  const openSettings = () => {
    navigate('/settings'); // TODO: Create global URL enum
  };

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  function handleCloseConfirm(): void {
    deleteConsultationSessionFromLocalStorage(deleteSessionId);
    const updatedSessions = { ...consultationSessions };
    delete updatedSessions[deleteSessionId];
    setConsultationSessions(updatedSessions);
    if (consultationSession?.session_id === deleteSessionId) {
      setConsultationSession(null);
    }
    setConsultationSessionDeleted(true);
    setTimeout(() => {
      setConsultationSessionDeleted(false);
    }, 200);
    setDeleteSessionId("");
    setShowAlertDialog(false);
  }

  function handleCloseCancel(): void {
    setShowAlertDialog(false);
  }

  return (
    <>
      <SnackbarNotification triggerOpen={consultationSessionDeleted} message={t('sessionDeleted')} color="warning" />
      <AlertDialog 
        show={showAlertDialog}
        setShowAlertDialog={setShowAlertDialog}
        confirmAction={handleCloseConfirm}
        cancelAction={handleCloseCancel}
        title={t('are_you_sure')}
        subtitle={t('really_want_to_delete')}
        buttonConfirmText={t('delete')}
        buttonCancelText={t('cancel')}
        buttonConfirmIcon={<Delete />}
        buttonCancelIcon={<CancelOutlined />}
      />
      <Drawer 
        open={drawerOpen}
        variant={isMd ? "temporary" : "persistent"}
        sx={{
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: DRAWER_WIDTH, boxSizing: 'border-box', backgroundColor: backgroundColor },
        }}
        ModalProps={{ onClose: handleDrawerClose }}
      >
        <DrawerHeader>
          <img 
            src="/logo_mini.png" 
            alt={t('troper')}
            style={{ padding: '0 10px', height: '40px' }}
          />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, ml: 1 }}>
            {t('troper')}
          </Typography>
          {
            !isDesktop &&
            <IconButton onClick={handleDrawerClose}>
              {<ChevronLeftIcon />}
            </IconButton>
          }
        </DrawerHeader>
        <Divider />
        <Box className="sidebar" bgcolor={backgroundColor}>
          <Button 
            onClick={handleNewConsultation}
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
          >
            {t('consultation')}
          </Button>
          <div className="consultations-list">
            {Object.entries(groupedSessions).reverse().map(([date, sessions]) => (
              <div key={date}>
                <Typography variant="caption" component="div" sx={{ mt: 2 }}>
                  {new Date(date).toLocaleDateString('es-ES', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  })}
                </Typography>
                {sessions.reverse().map((session, index) => (
                  <ConsultationItem
                    key={session.session_id}
                    session={session}
                    isSelected={consultationSession?.session_id === session.session_id}
                    setConsultationSession={setConsultationSession}
                    onDeleteSession={handleDeleteSession}
                    index={sessions.length - index}
                  />
                ))}
              </div>
            ))}
          </div>
          <Divider sx={{ my: 3 }} />
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={openSettings}>
                <ListItemIcon><SettingsIcon /></ListItemIcon>
                <ListItemText primary={t('settings')} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding >
              <ListItemButton onClick={onSignOut} sx={{ color: '#d32f2f' }}>
                <ListItemIcon><LogoutIcon sx={{ color: '#d32f2f' }} /></ListItemIcon>
                <ListItemText primary={t('signOut')} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default Sidebar;
