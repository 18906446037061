import { LoadingButton } from '@mui/lab';
import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AuthError, Session, SupabaseClient } from '@supabase/supabase-js';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function Copyright(props: any) {
  const { t } = useTranslation();
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {t('copyright')} {' © '}
      <Link color="inherit" href="https://app.troper.io/">
        Troper.io
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

interface ResetPasswordProps { 
  onSession: (session: Session | null) => void;
  supabase: SupabaseClient;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ onSession, supabase }) => {
  const [loading, setLoading] = useState(false);
  const [resetError, setResetError] = useState<AuthError | null>(null);
  const [completed, setCompleted] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const data = new FormData(event.currentTarget);
    const email = data.get('email');

    if (email) {
      const { data, error } = await supabase.auth.resetPasswordForEmail(email.toString(), {
        redirectTo: process.env.REACT_APP_BASE_URL + "/update-password",
      });
      if (error) {
        setResetError(error);
      } else {
        setCompleted(true);
      }
    }
    setLoading(false);
  };
  
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src="/logo.png" className='auth-logo'  />

        <Typography component="h1" variant="h5">
          {t('forgot_password')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('email')}
            name="email"
            autoComplete="email"
            autoFocus
          />
            {completed && 
              <Alert severity="success" sx={{mt:2}}>
                {t('reset_link_sent')}
              </Alert>
            }
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
          >
            {t('send_instructions')}
          </LoadingButton>
        </Box>
        <Grid container>
            <Grid item xs>
              <Link href="/sign-in" variant="body2">
                {t('sign_in')}
              </Link>
            </Grid>
            <Grid item>
              <Link href="/sign-up" variant="body2">
                {t('no_account')}
              </Link>
            </Grid>
          </Grid>
      </Box>
    </Container>
  );
};

export { ResetPassword };
