import React, { useEffect, useRef, useState } from 'react';
import { useAudioRecorder } from '../../services/AudioRecorder';
import { generateAndFormatNote } from '../../services/generateNoteService';
import { ConsultationSession, Section, UserInfo } from '../../types';
import ToolbarComponent from '../Navigation/Toolbar';
import TranscriptionTab from './TranscriptionTab';

import { ContactPage, Person, Receipt, Transcribe } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import { User } from '../../types';
import NoteTemplate from './NoteTemplate';
import RecordingIndicator from './tools/RecordingIndicator';
import { useTranslation } from 'react-i18next';
import ContextTab from './ContextTab';

export const NoteGenerated = {
  NotGenerated: 0,
  Generating: 1,
  Generated: 2,
};

enum NavBarTabs {
  context = 'context',
  transcript = 'transcript',
  note = 'note'
};

interface MainContentProps {
  consultationSession: ConsultationSession; 
  setConsultationSession: React.Dispatch<React.SetStateAction<ConsultationSession>>; 
  isDesktop: Boolean;
  user: User;
  templateSelected: string;
  transcriptionLanguage: string;
  noteLanguage: string;
  userInfo: UserInfo;
  setUserInfo: (userInfo: UserInfo) => void;
  setTranscriptionLanguage: (language: string) => void;
  setNoteLanguage: (language: string) => void;
  setTemplateSelected: (template: string) => void;
}

const MainContent: React.FC<MainContentProps> = ({ 
  consultationSession, 
  setConsultationSession, 
  isDesktop, 
  user,
  templateSelected,
  transcriptionLanguage,
  noteLanguage,
  userInfo,
  setUserInfo,
  setTranscriptionLanguage,
  setNoteLanguage,
  setTemplateSelected,
}) => {

  const [activeTab, setActiveTab] = useState<NavBarTabs>(NavBarTabs.context);
  const [combinedTranscription, setCombinedTranscription] = useState<string>('');
  const combinedTranscriptionRef = useRef<string>(combinedTranscription);
  const [clinicalNoteSections, setClinicalNoteSections] = useState<Section[]>([]);
  const [noteGenerated, setNoteGenerated] = useState<number>(NoteGenerated.NotGenerated);
  const [transcriptionStarted, setTranscriptionStarted] = useState<boolean>(false);
  const [consultationSessionId, setConsultationSessionId] = useState<string | null>(null);

  const { t } = useTranslation();

  const saveTranscription = () => {
    setConsultationSession((session: ConsultationSession) => ({
      ...session,
      transcription: combinedTranscriptionRef.current,
    }));
  };

  const saveNotes = (clinicalNotes: Section[]) => {
    setConsultationSession((session: ConsultationSession) => ({
      ...session,
      clinicalNoteSections: clinicalNotes,
    }));
  };

  const wsUrl = process.env.REACT_APP_BACKEND_WEBSOCKET_URL + '/ws/transcribe_streaming';
  const {
    wsSessionTranscription,
    setWsSessionTranscription,
    isRecording,
    startRecording,
    stopRecording,
  } = useAudioRecorder(wsUrl, transcriptionLanguage, templateSelected,  () => setTranscriptionStarted(true), saveTranscription);

  const clearConsultationSessionData = () => {
    setWsSessionTranscription('');
    setCombinedTranscription('');
    setClinicalNoteSections([]);
    if (isRecording) {
      stopRecording();
    }
  };

  const loadConsultationSessionData = () => {
    setCombinedTranscription(consultationSession.transcription);
    setClinicalNoteSections(consultationSession.clinicalNoteSections);
    setTranscriptionStarted(false);
  };

  const startRecordingWithMetrics = async () => {
    if (user?.id && consultationSession?.session_id) {
      await startRecording(
        consultationSession?.session_id,
        user?.id,
        userInfo?.account?.speciality_id
      );
    }
  };

  const generateNoteAndUpdateState = async () => {
    if (consultationSession.session_id) {
      if (noteGenerated !== NoteGenerated.Generating) {
        setNoteGenerated(NoteGenerated.Generating);
        await generateNote();
        setNoteGenerated(NoteGenerated.Generated);
      }
    }
  };

  const generateNote = async () => {
    const formattedNote = await generateAndFormatNote({ 
      combinedTranscription: combinedTranscription,
      sessionId: consultationSession.session_id,
      userId: user?.id,
      templateName: templateSelected,
      patientContext: consultationSession.context || '',
      noteLanguage: noteLanguage,
    });
    setClinicalNoteSections(formattedNote);
    saveNotes(formattedNote);
    setActiveTab(NavBarTabs.note);
    setNoteGenerated(NoteGenerated.Generated);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(NavBarTabs[newValue as keyof typeof NavBarTabs]);
  };

  useEffect(() => {
    if (consultationSession && consultationSession.session_id !== consultationSessionId) {
      clearConsultationSessionData();
      loadConsultationSessionData();
      setConsultationSessionId(consultationSession.session_id);
    }
  }, [consultationSession]); 

  useEffect(() => {
    combinedTranscriptionRef.current = combinedTranscription;
    setTranscriptionStarted(combinedTranscription.length > 0);
  }, [combinedTranscription]);
  
  useEffect(() => {
    clinicalNoteSections?.length ? setNoteGenerated(NoteGenerated.Generated) : setNoteGenerated(NoteGenerated.NotGenerated);
  }, [clinicalNoteSections]);
  
  useEffect(() => {
    if (!isRecording) {
      stopRecording();
    } else {
      setActiveTab(NavBarTabs.transcript);
    }
  }, [isRecording]);

  const [recordingStopped, setRecordingStopped] = useState(!isRecording);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (!isRecording) {
      timer = setTimeout(() => {
        setRecordingStopped(true);
      }, 500);
    } else {
      if (timer) {
        clearTimeout(timer);
      }
      setRecordingStopped(false);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isRecording]);

  useEffect(() => {
    let updatedTranscription = wsSessionTranscription;
    if (consultationSession) {
      updatedTranscription = wsSessionTranscription ? `${consultationSession.transcription}\n${wsSessionTranscription}` : consultationSession.transcription;
    }
    setCombinedTranscription(updatedTranscription);
  }, [wsSessionTranscription, isRecording]);

  
  // Scroll the container based on the active tab
  const tabListContainerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const container = tabListContainerRef.current;
    if (container) {
      if (activeTab === NavBarTabs.context) {
        container.scrollTo({ left: 0, behavior: 'smooth' });
      } else if (activeTab === NavBarTabs.transcript) {
        container.scrollTo({ left: container.scrollWidth / 2 - container.clientWidth / 2, behavior: 'smooth' });
      } else if (activeTab === NavBarTabs.note) {
        container.scrollTo({ left: container.scrollWidth, behavior: 'smooth' });
      }
    }
  }, [activeTab]);

  return (
    <Box className="main-content">
      {!recordingStopped && (
        <Box sx={{ position: "fixed", top: 0, right: 0, zIndex: 2000, m: 2 }}>
          <RecordingIndicator />
        </Box>
      )}
      <Box sx={{ p: 2, backgroundColor: grey[100], borderBottom: "1px solid rgba(0, 0, 0, 0.12);" }}>
        <ToolbarComponent
          transcriptionStarted={transcriptionStarted}
          isRecording={isRecording}
          hasTranscription={combinedTranscription.length > 0}
          startRecording={startRecordingWithMetrics}
          stopRecording={stopRecording}
          generateNote={generateNoteAndUpdateState}
          noteGenerated={noteGenerated}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          setTranscriptionLanguage={setTranscriptionLanguage}
          setNoteLanguage={setNoteLanguage}
          setTemplateSelected={setTemplateSelected}
          consultationSession={consultationSession} 
          setConsultationSession={setConsultationSession}
          sections={clinicalNoteSections} 
        />
      </Box>
      <TabContext value={activeTab}>
        <Box ref={tabListContainerRef} sx={{ px: 2, py: 1, overflowX: "scroll", borderBottom: '1px solid rgb(215 217 224)', }}>
          <TabList onChange={handleTabChange} variant={!isDesktop ? "fullWidth" : "standard"} sx={{ minWidth: 500 }}>
            <Tab label={t("context")} value={NavBarTabs.context} icon={<ContactPage />} iconPosition="start" sx={{ minHeight: 0, mr: 1 }} />
            <Tab label={t("transcription")} value={NavBarTabs.transcript} icon={<Transcribe />} iconPosition="start" sx={{ minHeight: 0, mr: 1 }} />
            <Tab label={t("note")} value={NavBarTabs.note} icon={<Receipt />} iconPosition="start" sx={{ minHeight: 0 }} />
          </TabList>
        </Box>
        <TabPanel value={NavBarTabs.context} sx={{ paddingTop: 0 }}>
          <ContextTab
            consultationSession={consultationSession}
            setConsultationSession={setConsultationSession}
          />
        </TabPanel>
        <TabPanel value={NavBarTabs.transcript} sx={{ paddingTop: 0 }}>
          <TranscriptionTab 
            combinedTranscription={combinedTranscription} 
            setCombinedTranscription={setCombinedTranscription} 
            saveTranscription={saveTranscription}  
            isRecording={isRecording}
          />
        </TabPanel>
        <TabPanel value={NavBarTabs.note} sx={{ paddingTop: 0 }}>
          <NoteTemplate 
            sections={clinicalNoteSections} 
            saveNote={saveNotes}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default MainContent;
