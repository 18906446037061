import { IconButton } from "@mui/material";
import React from "react";


import "./Sidebar.css";


import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { grey } from "@mui/material/colors";



interface AppToolbarProps {
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  isDesktop: Boolean;
}

const AppToolbar: React.FC<AppToolbarProps> = ({
 drawerOpen,
 setDrawerOpen,
 isDesktop,
}) => {

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <AppBar position="fixed" color="inherit" sx={{ display: {xs: "block", md: "none"}, backgroundColor: grey[200] }}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={handleDrawerOpen}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Troper
        </Typography>
      </Toolbar>
    </AppBar>
  );
};


export default AppToolbar;
