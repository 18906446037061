import React, { useEffect, useState } from 'react';
import { Modal, Checkbox, FormControlLabel, Button, Box, Typography, List, ListItem, Stack, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { Save, Settings } from '@mui/icons-material';
import { fetchAllTemplates, fetchUserTemplates, updateUserTemplates } from '../../../services/userService';
import { AvailableTemplates } from '@/types';
import { LoadingButton } from '@mui/lab';
import { AlertDialog } from '../AlertDialog';
import { useTranslation } from 'react-i18next'; // Importing useTranslation hook
import { extendedListOfSpecialities } from '../../../components/Auth/config';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "calc(100% - 32px)",
    maxWidth: 560,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

interface SelectAvailableTemplatesProps {
    availableTemplates: AvailableTemplates[];
    setAvailableTemplates: (templates: AvailableTemplates[]) => void;
}

const groupTemplates = (templates: AvailableTemplates[]) => {
    const groups = templates.reduce((acc, template) => {
        const speciality_id = template.speciality_id || 'general';
        if (!acc[speciality_id]) {
            acc[speciality_id] = [];
        }
        acc[speciality_id].push(template);
        return acc;
    }, {} as { [key: string]: AvailableTemplates[] });

    const orderedSpecialities = ['general', ...Object.keys(groups).filter(speciality_id => speciality_id !== 'general')];

    return orderedSpecialities.reduce((acc, speciality_id) => {
        acc[speciality_id] = groups[speciality_id];
        return acc;
    }, {} as { [key: string]: AvailableTemplates[] });
};



const SelectAvailableTemplates: React.FC<SelectAvailableTemplatesProps> = ({
    availableTemplates,
    setAvailableTemplates,
}) => {
    const [open, setOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [selectedItems, setSelectedItems] = useState<string[]>(availableTemplates.map((template) => template.template_id));
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [items, setItems] = useState<AvailableTemplates[]>();

    const { t, i18n } = useTranslation(); // Initializing translation hook
    const language: string = i18n.resolvedLanguage ? i18n.resolvedLanguage : "es"
    const translatedListOfSpecialities = extendedListOfSpecialities[language as keyof typeof extendedListOfSpecialities]

    useEffect(() => {
        fetchAllTemplates().then((data: AvailableTemplates[] | undefined) => { setItems(data || []) });
    }, []);

    useEffect(() => {
        setSelectedItems(availableTemplates.map((template) => template.template_id));
    }, [availableTemplates]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setSelectedItems(availableTemplates.map((template) => template.template_id));
        setOpen(false);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleCheckboxChange = (itemId: string) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(itemId)) {
                return prevSelectedItems.filter((id) => id !== itemId);
            } else {
                return [...prevSelectedItems, itemId];
            }
        });
    };

    const handleSave = async () => {
        if (selectedItems.length === 0) {
            setOpenAlert(true)
        } else {
            setIsLoading(true);
            await updateUserTemplates(selectedItems);
            await fetchUserTemplates().then((data: AvailableTemplates[] | undefined) => { setAvailableTemplates(data || []) });
            setIsLoading(false);
            handleClose();
        }
    };

    const groupedItems = items ? groupTemplates(items) : {};

    const selectAll = () => {
        if (selectedItems.length === items?.length) {
            setSelectedItems([]);
        } else {
            setSelectedItems(items?.map((item) => item.template_id) || []);
        }
    }

    return (
        <>
            <Dialog
                open={openAlert}
                onClose={handleCloseAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('select_at_least_one_template')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAlert}>OK</Button>
                </DialogActions>
            </Dialog>
            <Button
                variant="outlined"
                color="primary"
                startIcon={<Settings />}
                sx={{ m: 2 }}
                onClick={handleOpen}
            >
                {t('manageTemplates')}
            </Button>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <Typography variant="h6">{t('selectAvailableTemplates')}</Typography>
                    <FormControlLabel
                        sx={{ mt: 2 }}
                        control={
                            <Checkbox
                                checked={selectedItems.length === items?.length}
                                onChange={selectAll}
                            />
                        }
                        label={t('selectAll')}
                    />
                    <List sx={{ display: 'flex', flexDirection: 'column', maxHeight: "500px", overflow: 'scroll' }} >
                        {Object.entries(groupedItems).map(([speciality_id, templates]) => (
                            <div key={speciality_id}>
                                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                                    
                                </Typography>
                                {translatedListOfSpecialities.filter(el => el.id === speciality_id)[0]?.label.toString()}
                                {templates.map((item: AvailableTemplates) => (
                                    <ListItem key={item.template_id}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedItems.includes(item.template_id)}
                                                    onChange={() => handleCheckboxChange(item.template_id)}
                                                />
                                            }
                                            label={t(`template_names.${item.template_id}`)}
                                        />
                                    </ListItem>
                                ))}
                            </div>
                        ))}
                    </List>
                    <Stack spacing={2} direction="row-reverse">
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            startIcon={<Save />}
                            onClick={handleSave}
                            sx={{ mt: 2 }}
                            loading={isLoading}
                        >
                            {t('save')}
                        </LoadingButton>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
};

export default SelectAvailableTemplates;
