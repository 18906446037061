
import { User, CheckSubscriptionResponse } from "../types";
import { Session } from "@supabase/supabase-js";
import { api } from '../api/config'

async function updateUserFromSession(session: Session, user: User, setUser: (user : User) => void) {
    if (!session?.user) {
      console.error("Session or user data is missing.");
      return;
    }
  
    try {
      const subscriptionResponse = await checkUserSubscription();
  
      const name = session.user.user_metadata?.first_name  + " " + session.user.user_metadata?.last_name || "";
      const updatedUser : User = {
        ...user,
        id: session.user.id,
        email: session.user.email? session.user.email : "",
        name: name,
        activeSubscription: subscriptionResponse.valid, 
      };
  
      // Call onUser with the updatedUser object
      setUser(updatedUser);
    } catch (error) {
      console.error("Failed to update user from session:", error);
      // Handle the error appropriately. You might want to set some error state or rethrow the error.
    }
  }

  

const checkUserSubscription = async (): Promise<CheckSubscriptionResponse> => {
    try {
      let response: CheckSubscriptionResponse  = await api.get(
        'check_subscription'
      ).json();
      const { valid, message } = response;
      console.log("Subscription status:", message);    
  
      // Return an object that matches the CheckSubscriptionResponse interface
      return {
        valid,
        message
      };
    } catch (error) {
      console.error("Failed to fetch subscription status", error);
      // Return an object that conforms to the CheckSubscriptionResponse interface in case of an error
      return {
        valid: false,
        message: "Failed to fetch subscription status",
      };
    }
  };


  export { updateUserFromSession, checkUserSubscription };
  