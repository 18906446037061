import { CircularProgress, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { updatePreferredNoteLanguage } from '../../../services/userService';
import { noteLanguages } from '../../../transcriptionConfig';
import { UserInfo } from '../../../types';


interface SelectNoteLanguageProps { 
  userInfo: UserInfo; 
  setUserInfo: (userInfo: UserInfo) => void;
  setNoteLanguage: (language: string) => void;
  fullWidth?: boolean;
};

const SelectNoteLanguage: React.FC<SelectNoteLanguageProps> = ({ 
  userInfo,
  setUserInfo,
  setNoteLanguage,
  fullWidth,
}) => {

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    const target = event.target as HTMLInputElement | HTMLSelectElement;
    const { name, value } = target;
    setNoteLanguage(value);
    updatePreferredNoteLanguage(value);
    let user = { ...userInfo };
    user.settings[name as keyof typeof user.settings] = value;
    setUserInfo(user);
  }


  return (
    <Select
      id="preferred_note_language"
      sx={{ maxWidth: { lg: fullWidth ? "none" : 110 }}}
      size="small"
      value={userInfo.settings.preferred_note_language || "loading"}
      defaultValue=""
      name="preferred_note_language"
      onChange={handleChange}
      placeholder='Loading'
      disabled={!userInfo.settings.preferred_note_language}
      fullWidth
    >
      { 
        userInfo.settings.preferred_note_language 
        ? noteLanguages.map((language, index) => (
          <MenuItem key={index} value={language.code} defaultValue="es">
            {language.name}
          </MenuItem>
        )) 
        : <MenuItem value={"loading"}>
            <CircularProgress sx={{ maxHeight: "22px", maxWidth: "22px", position: "absolute", left: "50%", ml: "-11px" }} color="inherit" />
          </MenuItem>
      }

    </Select>
    );
  };

export { SelectNoteLanguage };
