import { AvailableTemplates, UserInfo, defaultAvailableTemplates } from '../../../types';
import { Add } from '@mui/icons-material';
import { Box, Button, Divider, MenuItem, Select, SelectChangeEvent, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { transcriptionLanguages } from '../../../transcriptionConfig';
import { DEFAULT_TEMPLTAE_ID } from '../SettingsPage';
import { SelectTranscriptionLanguage } from '../tools/SelectTranscriptionLanguage';
import { SelectTemplate } from '../tools/SelectTemplate';
import SortableTemplates from '../tools/SortableTemplates';
import SelectAvailableTemplates from '../tools/SelectAvailableTemplates';
import { SelectNoteLanguage } from '../tools/SelectNoteLanguage';
import { useTranslation } from 'react-i18next'; // Importing useTranslation hook

interface NotesComponentProps { 
  userInfo: UserInfo; 
  setUserInfo: (userInfo: UserInfo) => void;
  isDesktop: Boolean;
  setTemplateSelected: (templateId: string) => void;
  setTranscriptionLanguage: (language: string) => void;
  setNoteLanguage: (language: string) => void;
};

const NotesComponent: React.FC<NotesComponentProps> = ({ 
  userInfo,
  setUserInfo,
  isDesktop,
  setTemplateSelected,
  setTranscriptionLanguage,
  setNoteLanguage,
}) => {
  const { t } = useTranslation(); // Initializing translation hook
  const [availableTemplates, setAvailableTemplates] = React.useState<AvailableTemplates[]>(defaultAvailableTemplates);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    const target = event.target as HTMLInputElement | HTMLSelectElement;
    const { name, value } = target;
    name === 'preferred_template' && setTemplateSelected(value);
    name === 'preferred_language' && setTranscriptionLanguage(value);
    let user = { ...userInfo };
    user.settings[name as keyof typeof user.settings] = value;
    setUserInfo(user);
  }
  

  return (
    <>
      <Stack spacing={3} sx={{ mb: 5 }}>
        <Box>
          <Typography variant={isDesktop ? "h4" : "h5"}>
            {t('notesSettings')}
          </Typography>
          <Typography variant={isDesktop ? "body1" : "body2"}>
            {t('manageDefaultNotes')}
          </Typography>
        </Box>

        <Box>
          <Typography variant="h6">
            {t('transcriptionLanguage')}
          </Typography>
          <Typography variant="body1">
            {t('selectPreferredLanguage')}
          </Typography>
          <Box maxWidth={300}>
            <SelectTranscriptionLanguage
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              setTranscriptionLanguage={setTranscriptionLanguage}
              fullWidth
            />
          </Box>
        </Box>

        <Divider/>

        <Box>
          <Typography variant="h6">
            {t('noteLanguage')}
          </Typography>
          <Typography variant="body1">
            {t('selectPreferredNoteLanguage')}
          </Typography>
          <Box maxWidth={300}>
            <SelectNoteLanguage
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              setNoteLanguage={setNoteLanguage}
              fullWidth
            />
          </Box>
        </Box>

        <Divider/>

        <Box>
          <Typography variant="h6">
            {t('defaultTemplate')}
          </Typography>
          <Typography variant="body1">
            {t('selectOrCreateTemplate')}
          </Typography>

          <Box display={isDesktop ? 'flex' : 'block'}>
            <Box maxWidth={300} mt={1} mr={2}>
              <SelectTemplate
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                setTemplateSelected={setTemplateSelected}
                availableTemplates={availableTemplates}
                fullWidth
              />
            </Box>
            <Tooltip title={t('inDevelopment')}>
              <span>
                <Button
                  variant="outlined"
                  sx={{ mt: 1}}
                  color="primary" 
                  startIcon={<Add/>}
                  disabled
                  size='large'
                >
                  {t('createTemplate')}
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Box>
        <Box>
          <Typography variant="h6">
            {t('manageTemplates')}
          </Typography>
          <Typography variant="body1">
            {t('addDeleteAndSortTemplates')}
          </Typography>
          <Box maxWidth={560} mt={1} mr={2}>
            <SortableTemplates 
              availableTeplates={availableTemplates}
              setAvailableTeplates={setAvailableTemplates}
            />
            <SelectAvailableTemplates 
              availableTemplates={availableTemplates}
              setAvailableTemplates={setAvailableTemplates}
            />
          </Box>

        </Box>
      </Stack>
    </>
  );
};

export { NotesComponent };
