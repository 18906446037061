import React, { useEffect, useRef } from "react";
import "./styles/TranscriptionTab.css";
import { useTranslation } from 'react-i18next';

interface TranscriptionTabProps {
  combinedTranscription: string;
  setCombinedTranscription: (transcription: string) => void;
  saveTranscription: () => void;
  isRecording: boolean;
}

const TranscriptionTab: React.FC<TranscriptionTabProps> = ({
  combinedTranscription,
  setCombinedTranscription,
  saveTranscription,
  isRecording,
}) => {
  const { t } = useTranslation();
  // Handler function to update state based on keyboard input
  const handleChange = async (event: { target: { value: any; }; }) => {
    await setCombinedTranscription(event.target.value);
    saveTranscription();
  };

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [combinedTranscription]); 

  return (
    <>
      <textarea
        ref={textareaRef} 
        className="textareaStyle"
        placeholder={t('transcription_placeholder')}
        value={combinedTranscription}
        onChange={handleChange}
        disabled={isRecording}
      />
    </>
  );
};

export default TranscriptionTab;
