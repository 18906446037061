import { LoadingButton } from '@mui/lab';
import { Alert, Autocomplete } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { AuthError, Session, SupabaseClient } from '@supabase/supabase-js';
import * as EmailValidator from 'email-validator';
import Lottie from 'lottie-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import confirmEmailAnimaiton from "./assets/confirm-email-animation.json";
import { extendedListOfSpecialities } from './config';

function Copyright(props: any) {
    const { t } = useTranslation();
    return (
        <Typography variant="body2" color="text.secondary" align="center" padding-top="10px"
        {...props}>
            {t('copyright')} {' © '}
            <Link color="inherit" href="https://troper.io/">
                Troper.io
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function Disclaimer(props: any) {
    const { t } = useTranslation();
    return (
        <Typography variant="body2" color="text.secondary" align="center"{...props}>
            {t('disclaimer')}
        </Typography>
    );
}

const defaultTheme = createTheme();

interface SignUpProps {
    onSession: (session: Session | null) => void;
    supabase: SupabaseClient;
}

const SignUp: React.FC<SignUpProps> = ({ onSession, supabase }) => {
    const [loading, setLoading] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [signupError, setSignupError] = useState<AuthError | null>(null);
    const [completed, setCompleted] = useState(false);
    const { t, i18n } = useTranslation();

    const language: string = i18n.resolvedLanguage ? i18n.resolvedLanguage : "es"
    const translatedListOfSpecialities = extendedListOfSpecialities[language as keyof typeof extendedListOfSpecialities]

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            onSession(session);
        });

        const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
            onSession(session);
        });

        return () => subscription.unsubscribe();
    }, [onSession, supabase]);

    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);

        const data = new FormData(event.currentTarget);

        const firstName = data.get('firstName');
        const lastName = data.get('lastName');
        const email = data.get('email');
        const password = data.get('password');
        const specialization = data.get('specialization');

        let specializationId = translatedListOfSpecialities.find((speciality) => speciality.label === specialization)?.id;
        specializationId = specializationId ? specializationId : 'custom';
        
        if (email && !EmailValidator.validate(email.toString())) {
            setInvalidEmail(true);
        }

        if (password && password.toString().length < 6) {
            setInvalidPassword(true);
        }

        if (firstName && lastName && email && password && specialization && !invalidEmail && !invalidPassword) {
            const { data, error } = await supabase.auth.signUp({
                email: email.toString(),
                password: password.toString(),
                options: {
                    data: {
                        first_name: firstName.toString(),
                        last_name: lastName.toString(),
                        specialization: specialization.toString(),
                        speciality_id: specializationId.toString(),
                    }
                }
            });
            if (error) {
                setSignupError(error);
            } else {
                setCompleted(true);
                navigate("/sign-in"); // We directly navigate to the sign-in page instead of showing email_confirmation_sent message
            }
        };
        setLoading(false);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInvalidEmail(false);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInvalidPassword(false);
        setSignupError(null);
    };

    return (
        <Container component="main" maxWidth="xs">
            {
                completed ?
                    <div>
                        <Typography variant="h5" align="center" sx={{ mt: 10 }}>
                            {t('registration_complete')}
                        </Typography>
                        <Lottie animationData={confirmEmailAnimaiton} />
                        <Typography variant="h6" align="center">
                            {t('email_confirmation_sent')}
                        </Typography>
                    </div>
                    : <div>
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <img src="/logo.png" className='auth-logo' />

                            <Typography component="h1" variant="h5">
                                {t('sign_up')}
                            </Typography>
                            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"
                                            required
                                            fullWidth
                                            id="firstName"
                                            label={t('first_name')}
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="lastName"
                                            label={t('last_name')}
                                            name="lastName"
                                            autoComplete="family-name"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="email"
                                            label={t('email')}
                                            name="email"
                                            autoComplete="email"
                                            onChange={handleEmailChange}
                                            error={invalidEmail}
                                            helperText={invalidEmail && t('invalid_email')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="password"
                                            label={t('password')}
                                            type="password"
                                            id="password"
                                            autoComplete="new-password"
                                            onChange={handlePasswordChange}
                                            error={invalidPassword}
                                            helperText={invalidPassword && t('min_six_characters')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            disablePortal
                                            id="specialization"
                                            options={translatedListOfSpecialities}
                                            renderInput={(params) => <TextField {...params} label={t('specialization')} name="specialization" />}
                                            fullWidth
                                            freeSolo
                                        />
                                    </Grid>
                                    <Grid item xs={12} display={'flex'}>
                                        <Checkbox
                                            required
                                        />
                                        <Typography sx={{ mt: 1.2 }}>
                                            {t('accept_terms')}
                                            <Link href="https://www.troper.io/documentos-legales/terminos-y-condiciones-app" target="_blank" variant="body1" sx={{ ml: 1, mr: 1 }}>
                                                {t('terms_of_use')}
                                            </Link>
                                            {t('and_the')}
                                            <Link href="https://www.troper.io/documentos-legales/politica-privacidad-y-cookies-app" target="_blank" variant="body1" sx={{ ml: 1 }}>
                                                {t('privacy_policy')}
                                            </Link>
                                            &nbsp;*
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Disclaimer />
                                {signupError &&
                                    <Alert severity="error" sx={{ mt: 2 }}>
                                        {signupError?.message}
                                    </Alert>
                                }
                                <LoadingButton
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    loading={loading}
                                >
                                    {t('sign_up')}
                                </LoadingButton>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Link href="/sign-in" variant="body2">
                                            {t('already_have_account')}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <Copyright sx={{ mt: 5 }} />
                    </div>
            }
        </Container>
    );
};

export { SignUp };
