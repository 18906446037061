import { Box, CircularProgress, Zoom } from '@mui/material';
import { SupabaseClient } from '@supabase/supabase-js';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppToolbar from '../Navigation/AppToolbar';
import BreadcrumbComponent from './BreadcrumbComponent';
import SettingsDrawerComponent from './SettingsDrawerComponent';

import { UserInfo } from '@/types';
import { AccountCircle, CreditCard, Receipt, Save, Undo } from "@mui/icons-material";
import Button from '@mui/material/Button';
import deepEqual from 'deep-equal';
import { updateUserInfo } from '../../services/userService';
import { AlertDialog } from './AlertDialog';
import { AccountComponent } from './components/AccountComponent';
import { NotesComponent } from './components/NotesComponent';
import { SubscriptionComponent } from './components/SubscriptionComponent';
import { useTranslation } from 'react-i18next'; // Importing useTranslation hook

export const DEFAULT_TEMPLTAE_ID: string = "general_1a_consulta_template";
export const DEFAULT_TEMPLTAE_NAME: string = "Consulta General Inicial";
export const DEFAULT_TRANSCRIPTION_LANGUAGE: string = "es";

export interface SettingsPage {
  id: string;
  title: string;
  icon: React.ElementType;
}

export interface SettingsPages {
  ACCOUNT: SettingsPage;
  NOTES: SettingsPage;
  SUBSCRIPTION: SettingsPage;
}

export const settingsPages: SettingsPages = {
  ACCOUNT: {
    id: "account",
    title: "Información del usuario",
    icon: AccountCircle
  },
  NOTES: {
    id: "notes",
    title: "Notas",
    icon: Receipt
  },
  SUBSCRIPTION: {
    id: "subscription",
    title: "Suscripción",
    icon: CreditCard 
  },
}

interface SettingsPageProps { 
  supabase: SupabaseClient;
  isDesktop: Boolean
  defaultPage?: SettingsPage;
  setTemplateSelected: (template: string) => void;
  setTranscriptionLanguage: (language: string) => void;
  setNoteLanguage: (language: string) => void;
  userInfo: UserInfo | undefined;
  setUserInfo: (userInfo: UserInfo) => void;
};

const SettingsPage: React.FC<SettingsPageProps> = ({ 
  supabase,
  isDesktop,
  defaultPage = settingsPages.ACCOUNT,
  setTemplateSelected,
  setTranscriptionLanguage,
  setNoteLanguage,
  userInfo,
  setUserInfo,
}) => {
  const [loading, setLoading] = useState<Boolean>(Boolean(userInfo));
  const [originalUserInfo, setOriginalUserInfo] = useState<UserInfo>();
  const [selectedSettingsPage, setSettingsPage] = useState<SettingsPage>(defaultPage);
  const [userInfoChanged, setUserInfoChanged] = useState<Boolean>(false);
  const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [applicationLanguage, setApplicationLanguage] = useState<string>("es");

  const { t } = useTranslation(); // Initializing translation hook
  const navigate = useNavigate();

  useEffect(() => {
    if (originalUserInfo && userInfo?.account.id !== "" && !deepEqual(JSON.parse(JSON.stringify(userInfo)), originalUserInfo)) {
      if (selectedSettingsPage.id === settingsPages.ACCOUNT.id) {
        setUserInfoChanged(true);
      }
    } else {
      setUserInfoChanged(false);
    }
  }, [userInfo]);


  const saveUserInfo = async () => {
    if (userInfo) {
      try {
        await updateUserInfo(userInfo, originalUserInfo);
        await setOriginalUserInfo(JSON.parse(JSON.stringify(userInfo)));
        setUserInfoChanged(false);
        setTemplateSelected(userInfo.settings.preferred_template || DEFAULT_TEMPLTAE_ID);
        setTranscriptionLanguage(userInfo.settings.preferred_language || DEFAULT_TRANSCRIPTION_LANGUAGE);
        setApplicationLanguage(userInfo.settings.application_language || DEFAULT_TRANSCRIPTION_LANGUAGE);
      } catch (error) {
        console.error('Error saving user info:', error);
      }
    }
  }

  const revertChanges = () => {
    setUserInfo(JSON.parse(JSON.stringify(originalUserInfo)));
    setUserInfoChanged(false);
  }

  const handleCloseConfirm = async () => {
    await saveUserInfo();
    navigate('/');
  };

  const handleCloseCancel = async () => {
    await revertChanges();
    navigate('/');
  };


  useEffect(() => {
    if (userInfo?.account.id !== "" && !originalUserInfo) {
      setOriginalUserInfo(JSON.parse(JSON.stringify(userInfo)));
    }
    setTemplateSelected(userInfo?.settings.preferred_template || DEFAULT_TEMPLTAE_ID);
    setTranscriptionLanguage(userInfo?.settings.preferred_language || DEFAULT_TRANSCRIPTION_LANGUAGE);
  }, [userInfo]);

  useEffect(() => {
    setLoading(userInfo?.account.id === "")
  }, [userInfo]);

  const closeSettings = () => {
    if (
      !deepEqual(JSON.parse(JSON.stringify(userInfo?.account)), originalUserInfo?.account) ||
      userInfo?.settings.application_language !== originalUserInfo?.settings.application_language
    ) {
      setShowAlertDialog(true);
    } else {
      navigate('/');
    };
  }  

  return (
    <>
      <AlertDialog 
        show={showAlertDialog}
        setShowAlertDialog={setShowAlertDialog}
        confirmAction={handleCloseConfirm}
        cancelAction={handleCloseCancel}
        title={t('unsaved_changes')} 
        subtitle={t('unsaved_changes_subtitle')}
        buttonConfirmText={t('save')}
        buttonCancelText={t('discard_changes')}
        buttonConfirmIcon={<Save />}
        buttonCancelIcon={<Undo />}
      />
      <Box component="nav"
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1000 
        }}
      >
          <AppToolbar drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} isDesktop={isDesktop} />
      </Box>
      <Box component="nav" sx={{ ml: { xs: "20px", sm: "310px" }}} display="flex">
        <BreadcrumbComponent selectedSettingsPage={selectedSettingsPage} />
        { userInfoChanged && 
          <Box display={'flex'} sx={{ m: 2, position: "fixed", right: 0, bottom: 0, zIndex: 1000 }} >
            <Zoom in={true}>
              <Button 
                variant="contained"
                size="small" 
                color="error"
                onClick={revertChanges}
                sx={{ mr: 2, fontSize: '10px'}}
              >
                <Undo sx={{ mr: 1 }} />
                {t('discard_changes')}
              </Button>
            </Zoom>
            <Zoom in={true}>
              <Button 
                variant="contained"
                size="small" 
                color="success"
                onClick={saveUserInfo}
                sx={{ fontSize: '10px'}}
              >
                <Save sx={{ mr: 1 }} />
                {t('save')}
              </Button>
            </Zoom>
          </Box>
        }
       
      </Box>
      <Box>
        <SettingsDrawerComponent 
          settingsPages={settingsPages} 
          selectedSettingsPage={selectedSettingsPage} 
          setSettingsPage={setSettingsPage} 
          isDesktop={isDesktop} 
          closeSettings={closeSettings} 
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      </Box>
      <Box component="main" sx={{ ml: { xs: "20px", md: "310px" }, mt: {xs: 10}, mr: 3, pb: 2 }}>
        {!loading && userInfo ? 
          (
            selectedSettingsPage.id === 'account' ? 
              <AccountComponent 
                userInfo={userInfo} 
                setUserInfo={setUserInfo} 
                setApplicationLanguage={setApplicationLanguage}
                isDesktop={isDesktop} 
              /> 

            : selectedSettingsPage.id === 'notes' ? 
              <NotesComponent 
                userInfo={userInfo} 
                setUserInfo={setUserInfo} 
                isDesktop={isDesktop}
                setTemplateSelected={setTemplateSelected}
                setTranscriptionLanguage={setTranscriptionLanguage}
                setNoteLanguage={setNoteLanguage}
              />
            : selectedSettingsPage.id === 'subscription' && 
              <SubscriptionComponent 
                userInfo={userInfo} 
                isDesktop={isDesktop}
              />
          )
        : (

          <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          >
            <CircularProgress />
          </Box>
        )
        }
      </Box>
    </>
    );
  };

export { SettingsPage };
