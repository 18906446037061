import { LoadingButton } from '@mui/lab';
import { Alert, Autocomplete } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { AuthError, Session, SupabaseClient } from '@supabase/supabase-js';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { extendedListOfSpecialities } from './config';
import { fetchUserInfo } from '../../services/userService';
import { UserInfo } from '@/types';

function Copyright(props: any) {
    const { t } = useTranslation();
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {t('copyright')} {' © '}
            <Link color="inherit" href="https://troper.io/">
                Troper.io
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

interface AdditionalUserInfoProps {
    onSession: (session: Session | null) => void;
    supabase: SupabaseClient;
    setUserInfo: (userInfo: UserInfo) => void;
}

const CompleteRegistration: React.FC<AdditionalUserInfoProps> = ({ 
    onSession, 
    supabase,
    setUserInfo
}) => {
    const [loading, setLoading] = useState(false);
    const [signupError, setSignupError] = useState<AuthError | null>(null);
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const language: string = i18n.resolvedLanguage ? i18n.resolvedLanguage : "es"
    const translatedListOfSpecialities = extendedListOfSpecialities[language as keyof typeof extendedListOfSpecialities]

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);

        const data = new FormData(event.currentTarget);
        const specialization = data.get('specialization');

        let specializationId = translatedListOfSpecialities.find((speciality) => speciality.label === specialization)?.id;
        specializationId = specializationId ? specializationId : 'custom';

        if (specialization) {
            const { data, error } = await supabase.auth.updateUser({
                data: {
                    specialization: specialization.toString(),
                    speciality_id: specializationId.toString(),
                }
            });

            if (error) {
                setSignupError(error);
            } else {
                const userInfo = await fetchUserInfo();
                userInfo?.account?.id && setUserInfo(userInfo);
                navigate("/sign-in");
            }
        }
        setLoading(false);
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <img src="/logo.png" className='auth-logo' />

                <Typography component="h1" variant="h5">
                    {t('tell_us_about_yourself')}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} minWidth={396}>
                            <Autocomplete
                                disablePortal
                                id="specialization"
                                options={translatedListOfSpecialities}
                                renderInput={(params) => <TextField {...params} label={t('medical_specialization')} name="specialization" />}
                                fullWidth
                                freeSolo
                            />
                        </Grid>
                    </Grid>
                    {signupError && 
                        <Alert severity="error" sx={{ mt: 2 }}>
                            {signupError?.message}
                        </Alert>
                    }
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        loading={loading}
                    >
                        {t('confirm')}
                    </LoadingButton>
                </Box>
            </Box>
            <Copyright sx={{ mt: 5 }} />
        </Container>
    );
};

export { CompleteRegistration };
