import React, { useState, useEffect } from "react";
import "../styles/NavBar.css";
import LoadingButton from '@mui/lab/LoadingButton';
import CreateIcon from '@mui/icons-material/Create';
import { Stack } from "@mui/material";
import { useTranslation } from 'react-i18next';

enum NoteGenerated {
  NotGenerated,
  Generating,
  Generated,
}

interface NoteProps {
  hasTranscription: boolean;
  isRecording: boolean;
  generateNote: () => void;
  noteGenerated: NoteGenerated;
}

const Note: React.FC<NoteProps> = ({
  hasTranscription,
  isRecording,
  generateNote,
  noteGenerated,
}) => {
  const { t } = useTranslation();
  const [recordingStopped, setRecordingStopped] = useState(!isRecording);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (!isRecording) {
      timer = setTimeout(() => {
        setRecordingStopped(true);
      }, 500);
    } else {
      if (timer) {
        clearTimeout(timer);
      }
      setRecordingStopped(false);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isRecording]);

  const getNoteButtonText = (): string => {
    return noteGenerated === NoteGenerated.Generated 
      ? t('regenerate_note')
      : noteGenerated === NoteGenerated.Generating
      ? t('generating')
      : t('generate_note');
  };

  return (
    <LoadingButton 
      loading={noteGenerated === NoteGenerated.Generating}
      onClick={generateNote}
      variant="contained"
      color={hasTranscription ? "success" : "warning"}
      startIcon={<CreateIcon />}
      disabled={!hasTranscription || !recordingStopped}
      sx={{ maxWidth: { lg: 210 }, minWidth: { xs: 180, sm: 200 }, fontSize: { xs: 12, sm: 14 } }}
      fullWidth
    >
      {getNoteButtonText()}
    </LoadingButton>
  );
};

export default Note;
