import React, { useEffect, useState } from "react";

import PDFGenerator from "../../../components/MainContent/PDFGenerator";
import { Section } from "@/types";
import { Alert, Button, ButtonGroup, Snackbar, Tooltip } from "@mui/material";
import SnackbarNotification from "./SnackbarNotification";
import { useTranslation } from 'react-i18next';

interface ExportToolProps {
  sections: Section[];
}

const ExportTool: React.FC<ExportToolProps> = ({
  sections,
}) => {
  const { t } = useTranslation();
  const [exported, setExported] = useState<boolean>(false);
  const [message, setMessage] = useState<String>("");

  const formatMarkdownText = (): string => {
    return sections
      .map((section) => `${section.title}\n${section.content}\n`)
      .join("\n");
  }

  const openSnackbar = (message: String) => {
    setMessage(message);
    setExported(true);
  }

  useEffect(() => {
    exported && setTimeout(() => {
      setExported(false);
    }, 200);
  }
  ,[exported]);
  
  const copyMarkdownToClipboardAndNotify = async (message: String): Promise<void> => {
    const markdownText = formatMarkdownText();

    try {
      await navigator.clipboard.writeText(markdownText);
      openSnackbar(message);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const generateMarkdownPDFAndNotify = async (notification: String): Promise<void> => {
    const pdfGenerator = new PDFGenerator();
    pdfGenerator.generatePDF(sections);
    openSnackbar(notification);
  }

  return (
    <>
      <SnackbarNotification triggerOpen={exported} message={message} />
      <Tooltip title={ !sections && t('generate_note_first')}>
        <ButtonGroup 
          variant="outlined"
          color={"inherit"}
          disabled={!sections}
          size="small"
          >
          <Tooltip title={t('copy_to_clipboard')}>
            <Button
              onClick={() => copyMarkdownToClipboardAndNotify(t('copied_to_clipboard'))}
              >
              <img src={process.env.PUBLIC_URL + "/icons/export_clipboard_icon.png"} alt={t('copy_to_clipboard')} width={24} height={24} style={{ opacity: sections ? 1 : 0.6, transition: "200ms" }}/>
            </Button>
          </Tooltip>

          <Tooltip title={t('create_pdf')}>
            <Button
              onClick={() => generateMarkdownPDFAndNotify(t('pdf_generated'))}
              >
              <img src={process.env.PUBLIC_URL + "/icons/export_pdf_icon.png"} alt={t('export_pdf')} width={24} height={24} style={{ opacity: sections ? 1 : 0.6, transition: "200ms" }}/>
            </Button>
          </Tooltip>

          {/* <Tooltip title={t('create_word')}>
            <Button>
            <img src={process.env.PUBLIC_URL + "/icons/export_word_icon.png"} alt={t('export_word')} width={24} height={24}/>
            </Button>
          </Tooltip> */}

        </ButtonGroup>
      </Tooltip>
    </>
  );
};

export default ExportTool;
