import { Save, Undo } from "@mui/icons-material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { useTranslation } from 'react-i18next'; // Importing useTranslation hook

interface AlertDialogProps {
    show: boolean;
    setShowAlertDialog: (show: boolean) => void;
    title: string;
    subtitle: string;
    confirmAction: () => void;
    cancelAction: () => void;
    buttonConfirmText?: string;
    buttonCancelText?: string;
    buttonConfirmIcon?: React.ReactNode;
    buttonCancelIcon?: React.ReactNode;
};

const AlertDialog: React.FC<AlertDialogProps> = ({
    show = false,
    setShowAlertDialog,
    title,
    subtitle,
    confirmAction,
    cancelAction,
    buttonConfirmText,
    buttonCancelText,
    buttonConfirmIcon = <Save />,
    buttonCancelIcon = <Undo />,
}) => {
    const [open, setOpen] = React.useState<boolean>(show);
    const { t } = useTranslation(); // Initializing translation hook

    React.useEffect(() => {
        setOpen(show);
    }, [show]);

    const handleClose = () => {
        setOpen(false);
        setShowAlertDialog(false)
    };

    const handleConfirm = () => {
        confirmAction();
        handleClose();
    };
    
    const handleCancel = () => {
        cancelAction();
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {subtitle}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={handleCancel} 
                    startIcon={buttonCancelIcon}
                    color="error"
                >
                    {t(buttonCancelText || 'buttonCancelText')}
                </Button>
                <Button 
                    onClick={handleConfirm}
                    startIcon={buttonConfirmIcon}
                    color="success"
                >
                    {t(buttonConfirmText || 'buttonConfirmText')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { AlertDialog };
