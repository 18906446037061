import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import React, { useEffect } from "react";

interface AuthComponentProps {
  onSession: (session: Session | null) => void;
  supabase: SupabaseClient;
}

const AuthComponent: React.FC<AuthComponentProps> = ({ onSession, supabase }) => {
  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      onSession(session);
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      onSession(session);
    });

    return () => subscription.unsubscribe();
  }, [onSession, supabase]);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Auth
        supabaseClient={supabase}
        appearance={{ theme: ThemeSupa }}
        providers={["google"]}
        redirectTo={process.env.REACT_APP_BASE_URL}
      />
    </div>
  );
};

const signOut = async (onSession: (session: Session | null) => void, supabase: SupabaseClient): Promise<void> => {
  const { error } = await supabase.auth.signOut();
  if (!error) {
    onSession(null);
  } else {
    console.error("Sign out error:", error.message);
  }
  window.location.href = "/sign-in"
};

export { AuthComponent, signOut };
