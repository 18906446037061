import React, { useEffect } from "react";
import Lottie from "lottie-react";

import recordingAnimation from "../assets/recording-animation.json";
import { Box, Typography } from "@mui/material";

import { useSpring, animated, easings } from "react-spring";


const RecordingIndicator: React.FC = () => {
    const [props, api] = useSpring(() => ({
        from: { width: 0 },
        config: { 
            easing: easings.easeInExpo,
        },
    }), []);

    useEffect(() => {
        api.start({
            to: { width: 164 },
            delay: 200,
            onRest: () => {
                setTimeout(() => {
                    api.start({
                        to: { width: 0 },
                        config: { 
                            easing: easings.easeOutExpo,
                        },
                    });
                }, 2000);
            },
        });
    }, [api]);

    return (
        <animated.div style={props}>
            <Box height={24} sx={{ backgroundColor: "rgba(255, 205, 210, 0.5)", borderRadius: 10, overflow: "hidden" }} display={"flex"} zIndex={1000}>
                <Box width={200}>
                    <Typography variant={"body2"} sx={{ width: "200px", color: "red", ml: "12px", mt: "4px", fontSize: "12px" }}>Grabación en curso...</Typography>
                </Box>
                <Lottie animationData={recordingAnimation} style={{ height: 65, width: 65, position: "absolute", top: -22, right: -20 }}/>
            </Box>
        </animated.div>
    );
};

export default RecordingIndicator;
