import React, { useState } from "react";

import { ConsultationSession } from "../../types";
import "./Sidebar.css";


import { Delete } from "@mui/icons-material";
import { CardActionArea } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";


interface ConsultationItemProps {
    session: ConsultationSession;
    isSelected: boolean;
    setConsultationSession: (session: ConsultationSession) => void;
    onDeleteSession: (sessionId: string) => void;
    index?: number;
  }
  
  const ConsultationItem: React.FC<ConsultationItemProps> = ({
    session,
    isSelected,
    setConsultationSession,
    onDeleteSession,
    index,
  }) => {
    const [hover, setHover] = useState(false);

    const handleClick = () => {
      setConsultationSession(session);
    };
  
    const handleDelete = (e: React.MouseEvent) => {
      e.stopPropagation();
      onDeleteSession(session.session_id);
    };
  
    return (
      <>
      <Card 
      sx={{ maxWidth: 345, pr: 0 , my: 1, border: 0, borderRadius: 2, backgroundColor: isSelected ? grey[300]: grey[100]}}
      variant={"outlined"}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      >
      <CardActionArea
      onClick={handleClick}
      >
      <CardContent
        sx={{ py: "5px", px: "10px" }}
      >
        <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
        <Typography 
          gutterBottom 
          variant="body1" 
          component="div" 
          color={session.title === "" ? "grey" : ""} 
          sx={{display: "inline-block", whiteSpace: "nowrap", overflow: "hidden !important", textOverflow: "ellipsis", paddingRight: 3}}
        >
          {session.title ? session.title : 'Consulta ' + index }
        </Typography>
        { (hover || ( isSelected && matchMedia('(hover: none)').matches ) ) && 
        <Delete 
          onClick={handleDelete}
          sx={{ mr: -5, position: 'absolute', right: 10, top: 15}}
          opacity={0.5} 
        />
        }
        </Stack>
        <Typography variant="body2" color="text.secondary">
          {session.date && `${new Date(session.date).getHours().toString().padStart(2, '0')}:${new Date(session.date).getMinutes().toString().padStart(2, '0')}`}
        </Typography>
      </CardContent>
      </CardActionArea>
      </Card>
      </>
    );
  };
  
export default ConsultationItem;
  