import { api } from "../api/config";

interface GenerateAndFormatNoteParams {
  combinedTranscription: string;
  sessionId: string;
  userId: string;
  templateName: string;
  patientContext: string;
  noteLanguage: string;
}

interface Section {
  title: string;
  description: string;
  content: string;
}

interface FormattedNote {
  note: Section
}

export const generateAndFormatNote = async ({
  combinedTranscription,
  sessionId,
  userId,
  templateName,
  patientContext,
  noteLanguage = 'es',
}: GenerateAndFormatNoteParams): Promise<Section[]> => {
  try {
    const response: any = await api.post('generate_note', { json: 
      {
        transcription: combinedTranscription,
        session_id: sessionId,
        user_id: userId,
        template_name: templateName,
        patient_context: patientContext,
        language: noteLanguage,
      }
    }).json();
    
    return response;

  } catch (error) {
    console.error("Error generating note", error);
    return [];
  }
};
