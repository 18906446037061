import { api } from '../api/config';
import { ConsultationSession, Session } from "../types";


async function createNewSession(): Promise<ConsultationSession> {
  try {
    const response: ConsultationSession = await api.get('session').json();
    return response; 
  } catch (error) {
    console.error("Error creating new session", error);
    throw error;
  }
}

const storeConsultationSessionInLocalStorage = (session: Session): void => {
  const storedConsultationSessions: { [sessionId: string]: Session } = loadConsultationSessions();
  storedConsultationSessions[session.session_id] = session;
  localStorage.setItem("consultationSessions", JSON.stringify(storedConsultationSessions));
};

const loadConsultationSessions = (): { [sessionId: string]: Session } => {
  return JSON.parse(localStorage.getItem("consultationSessions") || "{}");
};

const deleteConsultationSessionFromLocalStorage = (
  sessionId: string
): void => {
  const storedConsultationSessions: { [sessionId: string]: Session } = loadConsultationSessions();
  delete storedConsultationSessions[sessionId];
  localStorage.setItem("consultationSessions", JSON.stringify(storedConsultationSessions));
};

const deleteAllConsultationSessions = (): void => {
  localStorage.removeItem("consultationSessions");
};

export {
  createNewSession, deleteAllConsultationSessions, deleteConsultationSessionFromLocalStorage, loadConsultationSessions,
  storeConsultationSessionInLocalStorage
};

