import { Settings } from '@mui/icons-material';
import { CircularProgress, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchUserTemplates, updatePreferredTemplate } from '../../../services/userService';
import { AvailableTemplates, UserInfo, defaultAvailableTemplates } from '../../../types';
import { useTranslation } from 'react-i18next';

interface SelectTemplateProps { 
  userInfo: UserInfo; 
  setUserInfo: (userInfo: UserInfo) => void;
  setTemplateSelected: (templateId: string) => void;
  availableTemplates?: AvailableTemplates[];
  fullWidth?: boolean;
  showSettings?: boolean;
};

const SelectTemplate: React.FC<SelectTemplateProps> = ({ 
  userInfo,
  setUserInfo,
  setTemplateSelected,
  availableTemplates,
  fullWidth,
  showSettings = false,
}) => {
    const [privateAvailableTemplates, setPrivateAvailableTemplates] = useState<AvailableTemplates[]>(defaultAvailableTemplates);
    const [selectValue, setSelectValue] = useState<string>("loading");
    const navigate = useNavigate();
    const { t } = useTranslation(); 
  
    useEffect(() => {
      if (!availableTemplates) {
        fetchUserTemplates().then((templates) => {
          if (templates) {
            setPrivateAvailableTemplates(templates);
          }
        });
      } else {
        setPrivateAvailableTemplates(availableTemplates);
      }
    }, [availableTemplates]);

    useEffect(() => {
      if (userInfo.settings.preferred_template) {
        setTemplateSelected(userInfo.settings.preferred_template)
      } else if (availableTemplates && availableTemplates[0].template_id !== "") {
        setTemplateSelected(availableTemplates[0].template_id)
      } 
    }
    , [availableTemplates, userInfo]);
    
    useEffect(() => {
      if (
        userInfo.settings.preferred_template &&
        !privateAvailableTemplates.map(template => template.template_id).includes(userInfo.settings.preferred_template)
      ) {
        const defaultTemplateId = privateAvailableTemplates[0]?.template_id || "loading";
        setSelectValue(defaultTemplateId);
        if (defaultTemplateId !== "loading") {
          setTemplateSelected(defaultTemplateId);
          updatePreferredTemplate(defaultTemplateId);
          setUserInfo({ ...userInfo, settings: { ...userInfo.settings, preferred_template: defaultTemplateId } });
        }
      } else if (userInfo.account.id !== "" && !userInfo.settings.preferred_template) {
        const defaultTemplateId = privateAvailableTemplates[0]?.template_id || "loading";
        setSelectValue(defaultTemplateId);
        if (defaultTemplateId !== "loading") {
          setTemplateSelected(defaultTemplateId);
          updatePreferredTemplate(defaultTemplateId);
          defaultTemplateId !== "loading" && setUserInfo({ ...userInfo, settings: { ...userInfo.settings, preferred_template: defaultTemplateId } });
        }
      } else {
        const defaultTemplateId =
          userInfo.settings.preferred_template ||
          (userInfo.account.id !== "" && privateAvailableTemplates[0]?.template_id) ||
          "loading";
          setSelectValue(defaultTemplateId);
      }
    }, [availableTemplates, privateAvailableTemplates, userInfo]);



  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    const target = event.target as HTMLInputElement | HTMLSelectElement;
    const { name, value } = target;
    if (value && value !== "loading") {
      setTemplateSelected(value);
      updatePreferredTemplate(value);
      setUserInfo({ ...userInfo, settings: { ...userInfo.settings, preferred_template: value } })
    } else if (availableTemplates) {
      setTemplateSelected(availableTemplates[0].template_id);
      updatePreferredTemplate(availableTemplates[0].template_id);
      setUserInfo({ ...userInfo, settings: { ...userInfo.settings, preferred_template: availableTemplates[0].template_id } })
    }
  }
  
  const openNotesSettings = () => {
    navigate('/settings/notes');
  };

  return (
    <Select
      id="template"
      sx={{ width: 200, whiteSpace: 'nowrap'}}
      size="small"
      value={selectValue}
      name="preferred_template"
      onChange={handleChange}
      disabled={selectValue === "loading"}
      fullWidth
      >
      { 
        selectValue !== "loading"
        ? privateAvailableTemplates.map((template, index) => (
          <MenuItem key={index} value={template.template_id}>
            {t(`template_names.${template.template_id}`,template.name)}
          </MenuItem>
        ))
        : <MenuItem value={"loading"}>
            <CircularProgress sx={{ maxHeight: "22px", maxWidth: "22px", position: "absolute", left: "50%", ml: "-11px" }} color="inherit" />
          </MenuItem>
      }
      { showSettings &&
        <MenuItem
        onClick={openNotesSettings}
        >
            <Settings color='primary' sx={{ mr: 1 }}/>
            <Typography color='primary'>Gestionar plantillas</Typography>
        </MenuItem>
      }
    </Select>
    );
  };

export { SelectTemplate };
