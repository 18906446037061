import React, { useEffect, useRef } from "react";
import "./styles/TranscriptionTab.css";
import { useTranslation } from 'react-i18next';
import { TextField, Typography } from "@mui/material";
import { ConsultationSession } from "@/types";

interface ContextTabProps {
  consultationSession: ConsultationSession;
  setConsultationSession: (session: ConsultationSession) => void;
}

const ContextTabTab: React.FC<ContextTabProps> = ({
  consultationSession,
  setConsultationSession,
}) => {
  const { t } = useTranslation();

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    let session = { ...consultationSession };
    session.context = event.target.value;
    setConsultationSession(session);
  }

  return (
    <>
      <textarea
        rows={15}
        placeholder={t('context_subtitle')}
        value={consultationSession?.context || ''}
        className="textareaStyle"
        onChange={onChange}
      />
    </>
  );
};

export default ContextTabTab;
