import { ArrowBack } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Fab } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AuthError, Session, SupabaseClient } from '@supabase/supabase-js';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Copyright(props: any) {
  const { t } = useTranslation();
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {t('copyright')} {' © '}
      <Link color="inherit" href="https://app.troper.io/">
        Troper.io
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

interface UpdatePasswordProps {
  onSession: (session: Session | null) => void;
  supabase: SupabaseClient;
}

const UpdatePassword: React.FC<UpdatePasswordProps> = ({ onSession, supabase }) => {
  const [loading, setLoading] = React.useState(false);
  const [updateError, setUpdateError] = React.useState<AuthError | null>(null);
  const { t } = useTranslation();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const from = queryParams.get('from');
  const allowBack = from !== null;

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const data = new FormData(event.currentTarget);
    const newPassword = data.get('password');

    const { error } = await supabase.auth.updateUser({
      password: newPassword?.toString()
    });

    if (error) {
      setUpdateError(error);
    } else {
      navigate("/");
    }
    setLoading(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      {allowBack &&
        <Fab 
          variant="extended" 
          sx={{ m: 2, position: "fixed", left: 0, top: 0, boxShadow: 0 }} 
          onClick={() => navigate(from?.toString() || "/")}
        >
          <ArrowBack sx={{ mr: 1 }}/>
          {t('back')}
        </Fab>
      }
      
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img src="/logo.png" className='auth-logo' />

        <Typography component="h1" variant="h5">
          {t('create_new_password')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('password')}
            type="password"
            id="password"
            autoComplete="current-password"
            error={updateError ? true : false}
          />
          {updateError &&
            <Alert severity="error" sx={{ mt: 2 }}>
              {updateError?.message}
            </Alert>
          }
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
          >
            {t('create_password')}
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
};

export { UpdatePassword };
