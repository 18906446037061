import GoogleIcon from '@mui/icons-material/Google';
import { LoadingButton } from '@mui/lab';
import { Alert, Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AuthError, Session, SupabaseClient } from '@supabase/supabase-js';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Copyright(props: any) {
  const { t } = useTranslation();
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {t('copyright')} {' © '}
      <Link color="inherit" href="https://app.troper.io/">
        Troper.io
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

interface SignInProps { 
  onSession: (session: Session | null) => void;
  supabase: SupabaseClient;
};
  
const SignIn: React.FC<SignInProps> = ({ onSession, supabase }) => {
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState<AuthError | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session) {
        onSession(session);
        navigate('/'); // Redirect if session exists
      }
    });
  }, [navigate, onSession, supabase]);

  useEffect(() => {
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      if (session) {
        onSession(session);
        navigate('/'); // Redirect on session change
      } else {
        onSession(null); // Update state on logout
      }
    });

    return () => subscription.unsubscribe();
  }, [navigate, onSession, supabase]);

  const loginWithGoogle = async () => {
    setLoading(true);
    await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: process.env.REACT_APP_BASE_URL
      }
    }).then(({ error }) => {
      if (error) {
        setLoginError(error);
        setLoading(false);
      }
      const session = supabase.auth.getSession()
      session.then((value) => {
        onSession(value.data.session)
      })
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');

    if (email && password) {
      const { error } = await supabase.auth.signInWithPassword({
        email: email.toString(),
        password: password.toString(),
      });
      if (error) {
        setLoginError(error);
      } else {
        navigate('/');
      }
    }
    setLoading(false);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (loginError) setLoginError(null);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (loginError) setLoginError(null);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src="/logo.png" className='auth-logo'  />

        <Typography component="h1" variant="h5">
          {t('sign_in')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('email')}
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleEmailChange}
            error={loginError ? true : false}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('password')}
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handlePasswordChange}
            error={loginError ? true : false}
          />
          {
            loginError && 
            <Alert severity="error" sx={{mt:2}}>
              {loginError?.message}
            </Alert>
          }
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
          >
            {t('sign_in')}
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <Link href="reset-password" variant="body2">
                {t('forgot_password')}
              </Link>
            </Grid>
            <Grid item>
              <Link href="/sign-up" variant="body2">
                {t('no_account')}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box>
        <Divider component="div" style={{width:'100%'}} sx={{mt:4, mb:1}}/>
        <Button
          type="submit"
          fullWidth
          variant="outlined"
          sx={{ mt: 3, mb: 2 }}
          startIcon={<GoogleIcon />}
          onClick={loginWithGoogle}
        >
          {t('sign_in_google')}
        </Button>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};

export { SignIn };
