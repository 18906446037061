import { UserInfo } from '../../../types';
import { CircularProgress, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { transcriptionLanguages } from '../../../transcriptionConfig';
import { updatePreferredLanguage } from '../../../services/userService';


interface SelectTranscriptionLanguageProps { 
  userInfo: UserInfo; 
  setUserInfo: (userInfo: UserInfo) => void;
  setTranscriptionLanguage: (language: string) => void;
  fullWidth?: boolean;
};

const SelectTranscriptionLanguage: React.FC<SelectTranscriptionLanguageProps> = ({ 
  userInfo,
  setUserInfo,
  setTranscriptionLanguage,
  fullWidth,
}) => {

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    const target = event.target as HTMLInputElement | HTMLSelectElement;
    const { name, value } = target;
    setTranscriptionLanguage(value);
    updatePreferredLanguage(value)
    let user = { ...userInfo };
    user.settings[name as keyof typeof user.settings] = value;
    setUserInfo(user);
  }


  return (
    <Select
      id="preferred_language"
      sx={{ maxWidth: { lg: fullWidth ? "none" : 110 }}}
      size="small"
      value={userInfo.settings.preferred_language || "loading"}
      defaultValue=""
      name="preferred_language"
      onChange={handleChange}
      placeholder='Loading'
      disabled={!userInfo.settings.preferred_language}
      fullWidth
    >
      { 
        userInfo.settings.preferred_language 
        ? transcriptionLanguages.map((language, index) => (
          <MenuItem key={index} value={language.code} defaultValue="es">
            {language.name}
          </MenuItem>
        )) 
        : <MenuItem value={"loading"}>
            <CircularProgress sx={{ maxHeight: "22px", maxWidth: "22px", position: "absolute", left: "50%", ml: "-11px" }} color="inherit" />
          </MenuItem>
      }

    </Select>
    );
  };

export { SelectTranscriptionLanguage };
