import React, { ChangeEvent, useEffect, useState } from "react";
import { storeConsultationSessionInLocalStorage } from "../../services/sessionService";
import { ConsultationSession } from "../../types";
import "./styles/Header.css";

import TextField from '@mui/material/TextField';
import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useTranslation } from 'react-i18next';

interface HeaderProps {
  consultationSession: ConsultationSession | null;
  setConsultationSession: (session: ConsultationSession) => void;
}

export const Header: React.FC<HeaderProps> = ({ consultationSession, setConsultationSession }) => {
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (consultationSession) {
      const updatedSession: ConsultationSession = {
        ...consultationSession,
        title: event.target.value,
      };
      setConsultationSession(updatedSession);
      storeConsultationSessionInLocalStorage(updatedSession);
    }
  };

  useEffect(() => {
    setInputFocused(false)
  }, [consultationSession?.session_id]); 

  const inputFocus = (input: any): void => {
    input.focus()
    setInputFocused(true)
  }

  return (
    <Box 
      className="header"
      mt={2}
    >
      <h2>
        <TextField
          type="text"
          value={consultationSession?.title || ''}
          onChange={handleChange}
          placeholder={t('consultation_name')}
          variant="standard"
          inputRef={input => (input && (consultationSession?.title === "" && !inputFocused)) && inputFocus(input)}
          fullWidth
          InputProps={{
            disableUnderline: !inputFocused, 
          }}
          sx={{
            "& fieldset": { border: 'none' },
          }}
        />
      </h2>
    </Box>
  );
};
