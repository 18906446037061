import { UserInfo } from '@/types';
import { Cancel, ShoppingCart } from '@mui/icons-material';
import { Box, Button, Chip, Divider, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next'; // Importing useTranslation hook

interface SubscriptionComponentProps { 
  userInfo: UserInfo;
  isDesktop: Boolean;
};

const SubscriptionComponent: React.FC<SubscriptionComponentProps> = ({ 
  userInfo,
  isDesktop
}) => {
  const { t } = useTranslation(); // Initializing translation hook

  const handleUpdateSubscription = () => {
    window.open(`https://www.troper.io/actualizar-suscripcion?email=${userInfo.account.email}&name=${userInfo.account.last_name ? userInfo.account.first_name + ' ' + userInfo.account.last_name : userInfo.account.first_name}`, '_blank');
  };

  const handleCancelSubscription = () => {
    window.open(`https://www.troper.io/cancelar-suscripcion?email=${userInfo.account.email}&name=${userInfo.account.last_name ? userInfo.account.first_name + ' ' + userInfo.account.last_name : userInfo.account.first_name}`, '_blank');
  };

  return (
    <>
      <Stack spacing={3} sx={{ mb: 5 }}>
        <Box>
          <Typography variant={isDesktop ? "h4" : "h5"}>
            {t('yourSubscription')}
          </Typography>
          <Typography variant={isDesktop ? "body1" : "body2"}>
            {t('manageYourSubscription')}
          </Typography>
        </Box>

        <Box>
          <Typography variant="h6">
            {t('metrics')}
          </Typography>
          <Typography variant="body1">
            {t('usageStatistics')}
          </Typography>
        
          <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mt: 0 }}>
            <Grid item xs={isDesktop ? 6 : 12}>
              
              <Box>
                <Typography variant="h6" align="center">
                  {t('remainingTime')}
                </Typography>
                <Typography variant="body2" align="center">
                  {t('remainingSubscriptionTime')}
                </Typography>

                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ mt: 2 }}
                >
                  <Chip
                    label={formatTime(userInfo.metrics?.seconds_remaining)}
                    sx={{ height: 50, width: 100, fontSize: '1.2rem', borderRadius: 10}}
                  />
                </Box>

              </Box>

            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              
              <Box>
                <Typography variant="h6" align="center">
                  {t('consumedTime')}
                </Typography>
                <Typography variant="body2" align="center">
                  {t('totalConsumedTime')}
                </Typography>

                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ mt: 2 }}
                >
                  <Chip
                    label={formatTime(userInfo.metrics?.sessions_duration)}
                    sx={{ height: 50, width: 100, fontSize: '1.2rem', borderRadius: 10}}
                  />
                </Box>

              </Box>

            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              
              <Box>
                <Typography variant="h6" align="center">
                  {t('notesGenerated')}
                </Typography>
                <Typography variant="body2" align="center">
                  {t('generatedNotesCount')}
                </Typography>

                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ mt: 2 }}
                >
                  <Chip
                    label={userInfo.metrics?.notes_generated || 0}
                    sx={{ height: 50, width: 100, fontSize: '1.2rem', borderRadius: 10}}
                    color="success"
                  />
                </Box>
                
              </Box>

            </Grid>
            <Grid item xs={isDesktop ? 6 : 12}>
              
              <Box>
                <Typography variant="h6" align="center">
                  {t('estimatedTimeSaved')}
                </Typography>
                <Typography variant="body2" align="center">
                  {t('timeSavedThanksToTroper')}
                </Typography>

                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ mt: 2 }}
                >
                  <Chip
                    label={formatTime((userInfo.metrics.time_saved || 0))}
                    sx={{ height: 50, width: 100, fontSize: '1.2rem', borderRadius: 10}}
                    color="success"
                  />
                </Box>

              </Box>

            </Grid>
          </Grid>
            
        </Box>

        <Divider/>

        <Box>
          <Typography variant="h6">
            {t('changeYourPlan')}
          </Typography>
          <Typography variant="body1">
            {t('chooseAnExtendedPlan')}
          </Typography>
          <Box display={'flex'}>
            <Button
              variant="contained"
              sx={{ mt: 3, mx: 2}}
              color="primary" 
              startIcon={<ShoppingCart/>}
              onClick={handleUpdateSubscription}
              >
              {t('updateSubscription')}
            </Button>
          </Box>
        </Box>
      
        <Divider/>

        <Box>
          <Typography variant="h6">
            {t('cancelYourSubscription')}
          </Typography>
          <Typography variant="body1">
            {t('stopUsingTroper')}
          </Typography>
          <Box display={'flex'}>
            <Button
              variant="outlined"
              sx={{ mt: 3, mx: 2}}
              color="error" 
              startIcon={<Cancel/>}
              onClick={handleCancelSubscription}
            >
              {t('cancelSubscription')}
            </Button>
          </Box>
        </Box>
      </Stack>
      
    </>

  );

  function formatTime(seconds: number | undefined): string {
    if (seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      return `${hours}h ${minutes}m`;
    } else {
      return '0h 0m';
    }
  }
};

export { SubscriptionComponent };
