import React, { useEffect, useState } from "react";
import "../styles/NavBar.css";

import MicIcon from '@mui/icons-material/Mic';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { LoadingButton } from "@mui/lab";
import { useTranslation } from 'react-i18next';

interface TranscriptionProps {
  transcriptionStarted: boolean;
  hasTranscription: boolean;
  isRecording: boolean;
  startRecording: () => void;
  stopRecording: () => void;
  sessionId?: string;
}

const Transcription: React.FC<TranscriptionProps> = ({
  transcriptionStarted,
  hasTranscription,
  isRecording,
  startRecording,
  stopRecording,
  sessionId,
}) => {
  const { t } = useTranslation();
  const [buttonClicked, setButtonClicked] = useState<boolean>(false)
  const [recordingStopped, setRecordingStopped] = useState<boolean | undefined>(undefined)
  const [recordingStopDelay, setRecordingStopDelay] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getRecordingButtonText = (): string => {
    if ((!transcriptionStarted || recordingStopped === undefined) && !hasTranscription) return t('start_consultation');
    if ((recordingStopped === true && transcriptionStarted) || (hasTranscription && recordingStopped === undefined)) return t('continue');
    return t('pause');
  };

  const getRecordingButtonColor = (): "success" | "error" | "warning" => {
    if ((!transcriptionStarted || recordingStopped === undefined) && !hasTranscription) return "success";
    if ((recordingStopped === true && transcriptionStarted) || (hasTranscription && recordingStopped === undefined)) return "warning";
    return "error";
  }
  
  const getRecordingButtonIcon = (): React.JSX.Element => {
    if ((!transcriptionStarted || recordingStopped === undefined) && !hasTranscription) return <MicIcon />;
    if ((recordingStopped === true && transcriptionStarted) || (hasTranscription && recordingStopped === undefined)) return  <PlayArrowIcon />;
    return <PauseIcon />;
  }

  const handleRecordingButtonClick = async (): Promise<void> => {
    setButtonClicked(true);
    if (recordingStopped || recordingStopped === undefined) {
      setRecordingStopped(false);
      setButtonClicked(false);
      await startRecording();
    } else {
      setRecordingStopDelay(true);
      setTimeout(async () => {
        await stopRecording();
        setRecordingStopDelay(false);
        setButtonClicked(false);
        setRecordingStopped(true);
      }, 3000);
    }
  };

  useEffect(() => {
    if (!isRecording && !recordingStopped && !buttonClicked) {
      console.log("Restarting recording ...")
      startRecording();
    }
  }, [isRecording]);

  useEffect(() => {
    if (isRecording) {
      setRecordingStopped(false);
    } else {
      setTimeout(() => {
        recordingStopped && setRecordingStopped((!isRecording && !buttonClicked))
      }, 500);
    }
  }, [isRecording]);

  useEffect(() => {
    setRecordingStopped(undefined);
  }, [sessionId]);
  
  useEffect(() => {
    setIsLoading(sessionId === undefined || recordingStopDelay)
  }, [sessionId, recordingStopDelay]);

  return (
    <>
      <LoadingButton
        onClick={handleRecordingButtonClick}
        variant="contained"
        color={getRecordingButtonColor()}
        startIcon={getRecordingButtonIcon()}
        sx={{ maxWidth: { lg: 220 }, minWidth: {xs: 178, sm: 200}, fontSize: {xs: 12, sm: 14} }}
        loading={isLoading}
        fullWidth
      >
        {getRecordingButtonText()}
      </LoadingButton>
    </>
  );
};

export default Transcription;
