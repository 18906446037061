import { ArrowBack } from "@mui/icons-material";
import { Button, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from "@mui/material";
import Divider from "@mui/material/Divider";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Importing useTranslation hook
import { SettingsPage, SettingsPages } from "./SettingsPage";

interface SidebarProps {
  settingsPages: SettingsPages;
  selectedSettingsPage: SettingsPage;
  setSettingsPage: (settingsPage: SettingsPage) => void;
  isDesktop: Boolean;
  closeSettings: () => void;
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  settingsPages,
  selectedSettingsPage,
  setSettingsPage,
  isDesktop,
  closeSettings,
  drawerOpen,
  setDrawerOpen
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(); // Initializing translation hook

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleClick = (settingsPage: SettingsPage) => () => {
    setSettingsPage(settingsPage);
    navigate(`/settings/${settingsPage.id}`);
    setDrawerOpen(false);
  }

  return (
    <Drawer 
      open={isDesktop ? true : drawerOpen}
      variant={isDesktop ? "persistent" : "temporary"}
      onClose={handleDrawerClose} 
      
    >
      <Toolbar>
        <Button 
          onClick={closeSettings}
          startIcon={<ArrowBack />}
          fullWidth
        >
          {t('closeSettings')}
        </Button>
      </Toolbar>
      <Divider />
      <List
      sx={{ width: '280px' }}>
        {Object.entries(settingsPages).map(([key, { id, title, icon }]) => (
          <ListItem key={key} disablePadding>
            <ListItemButton onClick={handleClick({ id, title, icon })} selected={selectedSettingsPage.id === id}>
              <ListItemIcon>{React.createElement(icon)}</ListItemIcon>
              <ListItemText primary={t(`settingsPageTitle.${id}`)} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
