import React from "react";
import "./styles/NavBar.css";

import Stack from '@mui/material/Stack';

import Note from "./Tools/Note";
import Transcription from "./Tools/Transcription";
import ExportTool from "./Tools/ExportTool";
import { Box, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { SelectTranscriptionLanguage } from "../Settings/tools/SelectTranscriptionLanguage";
import { ConsultationSession, Section, UserInfo } from "@/types";
import { SelectTemplate } from "../Settings/tools/SelectTemplate";
import { Header } from "../MainContent/Header";
import { SelectNoteLanguage } from "../Settings/tools/SelectNoteLanguage";
import { useTranslation } from 'react-i18next'; // Importing useTranslation hook

enum NoteGenerated {
  NotGenerated,
  Generating,
  Generated,
}

interface ToolbarProps {
  transcriptionStarted: boolean;
  isRecording: boolean;
  hasTranscription: boolean;
  startRecording: () => void;
  stopRecording: () => void;
  generateNote: () => void;
  noteGenerated: NoteGenerated;
  userInfo: UserInfo;
  setUserInfo: (userInfo: UserInfo) => void;
  setTranscriptionLanguage: (language: string) => void;
  setNoteLanguage: (language: string) => void;
  setTemplateSelected: (template: string) => void;
  consultationSession: ConsultationSession;
  setConsultationSession: (session: ConsultationSession) => void;
  sections: Section[];
}

const ToolbarComponent: React.FC<ToolbarProps> = ({
  transcriptionStarted,
  isRecording,
  hasTranscription,
  startRecording,
  stopRecording,
  generateNote,
  noteGenerated,
  userInfo,
  setUserInfo,
  setTranscriptionLanguage,
  setNoteLanguage,
  setTemplateSelected,
  consultationSession,
  setConsultationSession,
  sections,
}) => {
  const { t } = useTranslation(); // Initializing translation hook

  return (
    <>
      <Grid 
        container 
        spacing={1}
        justifyContent={"space-between"}
      >
        <Grid item xs={9} sm={"auto"} minWidth={{xs: `calc(100% - 150px)`, xl: "100%",}}>
          <Header
            consultationSession={consultationSession}
            setConsultationSession={setConsultationSession}
          />
        </Grid>

        <Grid item xs={3} sm={"auto"} sx={{display: {xs: "block", sm:"block", xl: "none"}}}>
          <Typography variant="subtitle2" color={grey[600]} mb={1}>
            {t('export')}
          </Typography>
          <ExportTool
            sections={sections}
          />
        </Grid>

        <Grid item xs={6} sm={12} md={6} lg={5} xl={4}>
          <Typography variant="subtitle2" color={grey[600]} mb={1}>
            {t('transcription')}
          </Typography>
          <Stack direction={{ xs: "column-reverse", sm: "row", md: "column", lg: "row" }} spacing={1}>
            <Transcription
              transcriptionStarted={transcriptionStarted}
              isRecording={isRecording}
              hasTranscription={hasTranscription}
              startRecording={startRecording}
              stopRecording={stopRecording}
              sessionId={consultationSession?.session_id}
            />
            <Box width={"100%"} display={{xs: "none", sm: "block"}}>
              <SelectTranscriptionLanguage
                userInfo={userInfo}
                setUserInfo={setUserInfo}
                setTranscriptionLanguage={setTranscriptionLanguage}
              />
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={6} sm={12} md={6} lg={7} xl={6}>
          <Typography variant="subtitle2" color={grey[600]} mb={1}>
            {t('note')}
          </Typography>
          <Stack direction={{ xs: "column-reverse", sm: "row", md: "column", lg: "row"}} spacing={1} >
            <Note
              hasTranscription={hasTranscription}
              isRecording={isRecording}
              generateNote={generateNote}
              noteGenerated={noteGenerated}
            />
            <Box display={{xs: "none", sm: "block"}}>
              <Stack direction={{xs: "column", sm: "row"}} spacing={1}>
                <SelectTemplate
                  userInfo={userInfo}
                  setUserInfo={setUserInfo}
                  setTemplateSelected={setTemplateSelected}
                  showSettings
                />
                <SelectNoteLanguage
                  userInfo={userInfo}
                  setUserInfo={setUserInfo}
                  setNoteLanguage={setNoteLanguage}
                />
              </Stack>
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={2} sx={{display: {xs: "none", xl: "block"}}}>
          <Typography variant="subtitle2" color={grey[600]} mb={1}>
            {t('export')}
          </Typography>
          <ExportTool
            sections={sections}
          />
        </Grid>

      </Grid>
    </>
  );
};

export default ToolbarComponent;
