export const extendedListOfSpecialities = { 
    "es": [
        { label: "General", id: "general"},
        { label: "Alergología", id: "allergology" },
        { label: "Anatomía Patológica", id: "pathological-anatomy" },
        { label: "Anestesiología y Reanimación", id: "anesthesiology-resuscitation" },
        { label: "Angiología y Cirugía Vascular", id: "angiology-vascular-surgery" },
        { label: "Aparato Digestivo", id: "digestive-system" },
        { label: "Cardiología", id: "cardiology" },
        { label: "Cirugía Cardiovascular", id: "cardiovascular-surgery" },
        { label: "Cirugía General y del Aparato Digestivo", id: "general-digestive-system-surgery" },
        { label: "Cirugía Oral y Maxilofacial", id: "oral-maxillofacial-surgery" },
        { label: "Cirugía Ortopédica y Traumatología", id: "orthopedic-surgery-traumatology" },
        { label: "Cirugía Pediátrica", id: "pediatric-surgery" },
        { label: "Cirugía Plástica, Estética y Reparadora", id: "plastic-aesthetic-reconstructive-surgery" },
        { label: "Cirugía Torácica", id: "thoracic-surgery" },
        { label: "Dermatología Médico-Quirúrgica y Venereología", id: "medical-surgical-dermatology-venereology" },
        { label: "Endocrinología", id: "endocrinology" },
        { label: "Endoscopio Digestivo", id: "digestive-endoscopy" },
        { label: "Farmacología Clínica", id: "clinical-pharmacology" },
        { label: "Fisioterapia", id: "physiotherapy" },
        { label: "Gastroenterología", id: "gastroenterology" },
        { label: "Geriatría", id: "geriatrics" },
        { label: "Hematología y Hemoterapia", id: "hematology-hemotherapy" },
        { label: "Hospital a Domicilio", id: "home-hospitalization" },
        { label: "Inmunología", id: "immunology" },
        { label: "Medicina del Deporte", id: "sports-medicine" },
        { label: "Medicina del Trabajo", id: "occupational-medicine" },
        { label: "Medicina Familiar y Comunitaria", id: "family-community-medicine" },
        { label: "Medicina Física y Rehabilitación", id: "physical-medicine-rehabilitation" },
        { label: "Medicina Intensiva", id: "intensive-care-medicine" },
        { label: "Medicina Interna", id: "internal-medicine" },
        { label: "Medicina Nuclear", id: "nuclear-medicine" },
        { label: "Medicina Preventiva y Salud Pública", id: "preventive-medicine-public-health" },
        { label: "Nefrología", id: "nephrology" },
        { label: "Neumología", id: "pneumology" },
        { label: "Neurocirugía", id: "neurosurgery" },
        { label: "Neurofisiología Clínica", id: "clinical-neurophysiology" },
        { label: "Neurología", id: "neurology" },
        { label: "Nutrición", id: "nutrition" },
        { label: "Obstetricia y Ginecología", id: "obstetrics-gynecology" },
        { label: "Oftalmología", id: "ophthalmology" },
        { label: "Oncología Médica", id: "medical-oncology" },
        { label: "Oncología Radioterápica", id: "radiation-oncology" },
        { label: "Otorrinolaringología", id: "otorhinolaryngology" },
        { label: "Pediatría", id: "pediatrics" },
        { label: "Psicología", id: "psychology" },
        { label: "Psiquiatría", id: "psychiatry" },
        { label: "Radiodiagnóstico", id: "radiodiagnosis" },
        { label: "Reumatología", id: "rheumatology" },
        { label: "Urología", id: "urology" }
    ],
    "en": [
        { label: "General", id: "general"},
        { label: "Allergology", id: "allergology" },
        { label: "Pathological Anatomy", id: "pathological-anatomy" },
        { label: "Anesthesiology and Resuscitation", id: "anesthesiology-resuscitation" },
        { label: "Angiology and Vascular Surgery", id: "angiology-vascular-surgery" },
        { label: "Digestive System", id: "digestive-system" },
        { label: "Cardiology", id: "cardiology" },
        { label: "Cardiovascular Surgery", id: "cardiovascular-surgery" },
        { label: "General and Digestive System Surgery", id: "general-digestive-system-surgery" },
        { label: "Oral and Maxillofacial Surgery", id: "oral-maxillofacial-surgery" },
        { label: "Orthopedic Surgery and Traumatology", id: "orthopedic-surgery-traumatology" },
        { label: "Pediatric Surgery", id: "pediatric-surgery" },
        { label: "Plastic, Aesthetic and Reconstructive Surgery", id: "plastic-aesthetic-reconstructive-surgery" },
        { label: "Thoracic Surgery", id: "thoracic-surgery" },
        { label: "Medical-Surgical Dermatology and Venereology", id: "medical-surgical-dermatology-venereology" },
        { label: "Endocrinology", id: "endocrinology" },
        { label: "Digestive Endoscopy", id: "digestive-endoscopy" },
        { label: "Clinical Pharmacology", id: "clinical-pharmacology" },
        { label: "Physiotherapy", id: "physiotherapy" },
        { label: "Gastroenterology", id: "gastroenterology" },
        { label: "Geriatrics", id: "geriatrics" },
        { label: "Hematology and Hemotherapy", id: "hematology-hemotherapy" },
        { label: "Home Hospitalization", id: "home-hospitalization" },
        { label: "Immunology", id: "immunology" },
        { label: "Sports Medicine", id: "sports-medicine" },
        { label: "Occupational Medicine", id: "occupational-medicine" },
        { label: "Family and Community Medicine", id: "family-community-medicine" },
        { label: "Physical Medicine and Rehabilitation", id: "physical-medicine-rehabilitation" },
        { label: "Intensive Care Medicine", id: "intensive-care-medicine" },
        { label: "Internal Medicine", id: "internal-medicine" },
        { label: "Nuclear Medicine", id: "nuclear-medicine" },
        { label: "Preventive Medicine and Public Health", id: "preventive-medicine-public-health" },
        { label: "Nephrology", id: "nephrology" },
        { label: "Pneumology", id: "pneumology" },
        { label: "Neurosurgery", id: "neurosurgery" },
        { label: "Clinical Neurophysiology", id: "clinical-neurophysiology" },
        { label: "Neurology", id: "neurology" },
        { label: "Nutrition", id: "nutrition" },
        { label: "Obstetrics and Gynecology", id: "obstetrics-gynecology" },
        { label: "Ophthalmology", id: "ophthalmology" },
        { label: "Medical Oncology", id: "medical-oncology" },
        { label: "Radiation Oncology", id: "radiation-oncology" },
        { label: "Otorhinolaryngology", id: "otorhinolaryngology" },
        { label: "Pediatrics", id: "pediatrics" },
        { label: "Psychology", id: "psychology" },
        { label: "Psychiatry", id: "psychiatry" },
        { label: "Radiodiagnosis", id: "radiodiagnosis" },
        { label: "Rheumatology", id: "rheumatology" },
        { label: "Urology", id: "urology" }
    ],
    "ca": [
        { label: "General", id: "general"},
        { label: "Alergologia", id: "allergology" },
        { label: "Anatomia Patològica", id: "pathological-anatomy" },
        { label: "Anestesiologia i Reanimació", id: "anesthesiology-resuscitation" },
        { label: "Angiologia i Cirurgia Vascular", id: "angiology-vascular-surgery" },
        { label: "Aparell Digestiu", id: "digestive-system" },
        { label: "Cardiologia", id: "cardiology" },
        { label: "Cirurgia Cardiovascular", id: "cardiovascular-surgery" },
        { label: "Cirurgia General i de l'Aparell Digestiu", id: "general-digestive-system-surgery" },
        { label: "Cirurgia Oral i Maxil·lofacial", id: "oral-maxillofacial-surgery" },
        { label: "Cirurgia Ortopèdica i Traumatologia", id: "orthopedic-surgery-traumatology" },
        { label: "Cirurgia Pediàtrica", id: "pediatric-surgery" },
        { label: "Cirurgia Plàstica, Estètica i Reparadora", id: "plastic-aesthetic-reconstructive-surgery" },
        { label: "Cirurgia Toràcica", id: "thoracic-surgery" },
        { label: "Dermatologia Mèdico-Quirúrgica i Venereologia", id: "medical-surgical-dermatology-venereology" },
        { label: "Endocrinologia", id: "endocrinology" },
        { label: "Endoscòpia Digestiva", id: "digestive-endoscopy" },
        { label: "Farmacologia Clínica", id: "clinical-pharmacology" },
        { label: "Fisioteràpia", id: "physiotherapy" },
        { label: "Gastroenterologia", id: "gastroenterology" },
        { label: "Geriatria", id: "geriatrics" },
        { label: "Hematologia i Hemoteràpia", id: "hematology-hemotherapy" },
        { label: "Hospital a Domicili", id: "home-hospitalization" },
        { label: "Immunologia", id: "immunology" },
        { label: "Medicina de l'Esport", id: "sports-medicine" },
        { label: "Medicina del Treball", id: "occupational-medicine" },
        { label: "Medicina Familiar i Comunitària", id: "family-community-medicine" },
        { label: "Medicina Física i Rehabilitació", id: "physical-medicine-rehabilitation" },
        { label: "Medicina Intensiva", id: "intensive-care-medicine" },
        { label: "Medicina Interna", id: "internal-medicine" },
        { label: "Medicina Nuclear", id: "nuclear-medicine" },
        { label: "Medicina Preventiva i Salut Pública", id: "preventive-medicine-public-health" },
        { label: "Nefrologia", id: "nephrology" },
        { label: "Pneumologia", id: "pneumology" },
        { label: "Neurocirurgia", id: "neurosurgery" },
        { label: "Neurofisiologia Clínica", id: "clinical-neurophysiology" },
        { label: "Neurologia", id: "neurology" },
        { label: "Nutrició", id: "nutrition" },
        { label: "Obstetrícia i Ginecologia", id: "obstetrics-gynecology" },
        { label: "Oftalmologia", id: "ophthalmology" },
        { label: "Oncologia Mèdica", id: "medical-oncology" },
        { label: "Oncologia Radioteràpica", id: "radiation-oncology" },
        { label: "Otorinolaringologia", id: "otorhinolaryngology" },
        { label: "Pediatria", id: "pediatrics" },
        { label: "Psicologia", id: "psychology" },
        { label: "Psiquiatria", id: "psychiatry" },
        { label: "Radiodiagnòstic", id: "radiodiagnosis" },
        { label: "Reumatologia", id: "rheumatology" },
        { label: "Urologia", id: "urology" }
    ],
    "pt": [
        { label: "Geral", id: "general"},
        { label: "Alergologia", id: "allergology" },
        { label: "Anatomia Patológica", id: "pathological-anatomy" },
        { label: "Anestesiologia e Reanimação", id: "anesthesiology-resuscitation" },
        { label: "Angiologia e Cirurgia Vascular", id: "angiology-vascular-surgery" },
        { label: "Aparelho Digestivo", id: "digestive-system" },
        { label: "Cardiologia", id: "cardiology" },
        { label: "Cirurgia Cardiovascular", id: "cardiovascular-surgery" },
        { label: "Cirurgia Geral e do Aparelho Digestivo", id: "general-digestive-system-surgery" },
        { label: "Cirurgia Oral e Maxilofacial", id: "oral-maxillofacial-surgery" },
        { label: "Cirurgia Ortopédica e Traumatologia", id: "orthopedic-surgery-traumatology" },
        { label: "Cirurgia Pediátrica", id: "pediatric-surgery" },
        { label: "Cirurgia Plástica, Estética e Reconstrutiva", id: "plastic-aesthetic-reconstructive-surgery" },
        { label: "Cirurgia Torácica", id: "thoracic-surgery" },
        { label: "Dermatologia Médico-Cirúrgica e Venereologia", id: "medical-surgical-dermatology-venereology" },
        { label: "Endocrinologia", id: "endocrinology" },
        { label: "Endoscopia Digestiva", id: "digestive-endoscopy" },
        { label: "Farmacologia Clínica", id: "clinical-pharmacology" },
        { label: "Fisioterapia", id: "physiotherapy" },
        { label: "Gastroenterologia", id: "gastroenterology" },
        { label: "Geriatria", id: "geriatrics" },
        { label: "Hematologia e Hemoterapia", id: "hematology-hemotherapy" },
        { label: "Hospitalização Domiciliar", id: "home-hospitalization" },
        { label: "Imunologia", id: "immunology" },
        { label: "Medicina do Esporte", id: "sports-medicine" },
        { label: "Medicina do Trabalho", id: "occupational-medicine" },
        { label: "Medicina de Família e Comunidade", id: "family-community-medicine" },
        { label: "Medicina Física e Reabilitação", id: "physical-medicine-rehabilitation" },
        { label: "Medicina Intensiva", id: "intensive-care-medicine" },
        { label: "Medicina Interna", id: "internal-medicine" },
        { label: "Medicina Nuclear", id: "nuclear-medicine" },
        { label: "Medicina Preventiva e Saúde Pública", id: "preventive-medicine-public-health" },
        { label: "Nefrologia", id: "nephrology" },
        { label: "Pneumologia", id: "pneumology" },
        { label: "Neurocirurgia", id: "neurosurgery" },
        { label: "Neurofisiologia Clínica", id: "clinical-neurophysiology" },
        { label: "Neurologia", id: "neurology" },
        { label: "Nutrição", id: "nutrition" },
        { label: "Obstetrícia e Ginecologia", id: "obstetrics-gynecology" },
        { label: "Oftalmologia", id: "ophthalmology" },
        { label: "Oncologia Médica", id: "medical-oncology" },
        { label: "Oncologia Radioterápica", id: "radiation-oncology" },
        { label: "Otorrinolaringologia", id: "otorhinolaryngology" },
        { label: "Pediatria", id: "pediatrics" },
        { label: "Psicologia", id: "psychology" },
        { label: "Psiquiatria", id: "psychiatry" },
        { label: "Radiodiagnóstico", id: "radiodiagnosis" },
        { label: "Reumatologia", id: "rheumatology" },
        { label: "Urologia", id: "urology" }
    ]
};
