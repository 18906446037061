export interface Session {
    [key: string]: any;
    session_id: string
    title?: string;
}

export interface Section {
    title: string;
    // description: string;
    content: string;
    noteSections?: string;
}

export interface ConsultationSession {
    session_id: string;
    transcription: string;
    clinicalNoteSections: Section[];
    date: Date;
    title?: string;
    context?: string
}

export interface CheckSubscriptionResponse {
    valid: boolean;
    message: string;
}

export interface SupabaseAuthUserInfo {
    id: string | undefined;
    email: string | undefined;
}


export interface User {
    id: string;
    email: string;
    name: string | null;
    activeSubscription: boolean | undefined;
}

export const defaultUser: User = {
    id: "",
    email: "",
    name: "",
    activeSubscription: undefined,
}

export interface AvailableTemplates {
    id: number;
    template_id: string;
    name: string;
    order: number;
    speciality_id?: string;
}

export const defaultAvailableTemplates: AvailableTemplates[] = [
    { id: 0, template_id: "", name: "", speciality_id: "", order: 0}
];

interface Account {
    avatar_url: string;
    created_at: string;
    email: string;
    first_name: string;
    id: string;
    last_name: string | undefined;
    phone: string | undefined;
    speciality: string | undefined;
    speciality_id: string | undefined;
}

interface Settings {
    preferred_language: string;
    preferred_template: string | undefined;
    preferred_note_language: string;
    application_language: string | undefined;
    theme: string;
}

interface Subscription {
    created_at: string;
    expiration_time: string;
    plan: string;
    seconds_purchased: number;
    status: string;
    subscription_id: string;
}

interface Metrics {
    seconds_remaining: number | undefined;
    sessions_duration: number | undefined;
    notes_generated: number | undefined;
    time_saved: number | undefined;
}
  
export interface UserInfo {
    account: Account;
    settings: Settings;
    subscription: Subscription;
    metrics: Metrics;
}
  
export const defaultUserInfo: UserInfo = {
    account: {
        avatar_url: "",
        created_at: "",
        email: "",
        first_name: "",
        id: "",
        last_name: undefined,
        phone: undefined,
        speciality: undefined, // To be removed after with speciality_id is implemented
        speciality_id: undefined,
    },
    settings: {
        preferred_language: "",
        preferred_template: "",
        preferred_note_language: "",
        application_language: undefined,
        theme: "",
    },
    subscription: {
        created_at: "",
        expiration_time: "",
        plan: "",
        seconds_purchased: 0,
        status: "",
        subscription_id: "",
    },
    metrics: {
        seconds_remaining: undefined,
        sessions_duration: undefined,
        notes_generated: undefined,
        time_saved: undefined,
    }
}

export type AuthSessionType = any; // Replace 'any' with the actual type of your auth session
export type ConsultationSessionType = any; // Replace 'any' with the actual type of your consultation session

export const defaultSession = { 
    session_id: '', 
    title: '', 
    date: '', 
    description: '', 
    transcription: '' 
}