import { UserInfo } from '../../../types';
import { Lock, Mail, Phone, Warning } from '@mui/icons-material';
import { Avatar, Box, Button, Divider, InputAdornment, MenuItem, Select, SelectChangeEvent, Stack, TextField, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { extendedListOfSpecialities } from '../../Auth/config';
import { useTranslation } from 'react-i18next'; // Importing useTranslation hook
import { SelectApplicationLanguage } from '../tools/SelectApplicationLanguage';

interface AccountComponentProps { 
  userInfo: UserInfo;
  setUserInfo: (userInfo: UserInfo) => void;
  isDesktop: Boolean;
  setApplicationLanguage: (language: string) => void;
};

const AccountComponent: React.FC<AccountComponentProps> = ({ 
  userInfo,
  setUserInfo,
  isDesktop,
  setApplicationLanguage,
}) => {
  const { t, i18n } = useTranslation(); // Initializing translation hook
  const navigate = useNavigate();

  const language: string = i18n.resolvedLanguage ? i18n.resolvedLanguage : "es"
  const translatedListOfSpecialities = extendedListOfSpecialities[language as keyof typeof extendedListOfSpecialities]

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    const target = event.target as HTMLInputElement | HTMLSelectElement; 
    const { name, value } = target;
    let user = { ...userInfo };
    user.account[name as keyof typeof user.account] = value;
    setUserInfo(user);
  }

  return (
    <>
      <Stack spacing={3} sx={{ mb: 5 }}>
        <Box>
          <Typography variant={isDesktop ? "h4" : "h5"}>
            {t('userSettings')}
          </Typography>
          <Typography variant={isDesktop ? "body1" : "body2"}>
            {t('managePersonalDetails')}
          </Typography>
        </Box>

        <Box display={'flex'} sx={{ mt:2 }}> 
          <Avatar sx={{ m: 1, width: 56, height: 56 }} sizes='large' src={userInfo.account.avatar_url}/>
          <Box sx={{ m: 1 }}>
            <Typography variant="h5">
              {userInfo.account.first_name} {userInfo.account.last_name}
            </Typography>
            <Typography variant="body1">
              {userInfo.account.speciality}
            </Typography>
          </Box>
        </Box>

        <Divider/>

        <Box>
          <Typography variant="h6">
            {t('name')}
          </Typography>
          <Typography variant="body1">
            {t('updateName')}
          </Typography>
          <Box display={isDesktop ? 'flex' : 'block'}>
            <TextField
              name="first_name"
              label={t('firstName')}
              value={userInfo.account.first_name}
              variant="outlined"
              sx={{ mt: 2, minWidth: '300px', mx: 1 }}
              onChange={handleChange}
            />
            {
              userInfo.account.last_name &&
              <TextField
                name="last_name"
                label={t('lastName')}
                value={userInfo.account.last_name}
                variant="outlined"
                sx={{ mt: 2, minWidth: '300px', mx: 1}}
                onChange={handleChange}
              />
            }
          </Box>
        </Box>

        <Divider/>

        <Box>
          <Typography variant="h6">
            {t('contact')}
          </Typography>
          <Typography variant="body1">
            {t('manageContactInfo')}
          </Typography>
          <Box display={isDesktop ? 'flex' : 'block'}>
            <Tooltip title={t('contactUsToChange')}>
              <TextField
                name="email"
                label={t('email')}
                value={userInfo.account.email}
                variant="outlined"
                sx={{ mt: 2, minWidth: '300px', mx: 1 }}
                onChange={handleChange}
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Mail />
                    </InputAdornment>
                  ),
                }}
              />
            </Tooltip>
            <TextField
              name="phone"
              label={t('phoneNumber')}
              value={userInfo.account.phone || ''}
              placeholder={t('enterPhoneNumber')}
              variant="outlined"
              sx={{ mt: 2, mx: 1,  width: '300px' }}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Phone />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        <Divider/>

        <Box minWidth={'300px'}>
          <Typography variant="h6">
            {t('medicalSpeciality')}
          </Typography>
          <Typography variant="body1">
            {t('selectYourSpeciality')}
          </Typography>
          <Select
            sx={{ mt: 2, mx: 1, width: '300px' }}
            value={userInfo.account.speciality_id || ''}
            name="speciality_id"
            onChange={handleChange}
          >
            {translatedListOfSpecialities.map((speciality, index) => (
              <MenuItem key={index} value={speciality.id}>
                {speciality.label}
              </MenuItem>
            ))}
            <MenuItem value={'custom'}>{userInfo.account.speciality}</MenuItem>
          </Select>
        </Box>

        <Divider/>
        
        <Box minWidth={'300px'}>
          <Typography variant="h6">
            {t('application_language')}
          </Typography>
          <Typography variant="body1">
            {t('select_application_language')}
          </Typography>
          <Box sx={{ mt: 2, mx: 1 }}>
            <SelectApplicationLanguage 
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              setApplicationLanguage={setApplicationLanguage}
            />
          </Box>
        </Box>

        <Divider/>

        <Box>
          <Typography variant="h6">
            {t('password')}
          </Typography>
          <Typography variant="body1">
            {t('updatePassword')}
          </Typography>
          <Box display={isDesktop ? 'flex' : 'block'}>
            <TextField
              id="password"
              label={t('password')}
              type="password"
              variant="outlined"
              sx={{ mt: 2, mx: 1, minWidth: '300px' }}
              defaultValue={'********'}
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="outlined"
              sx={{ mt: 2, mx: 1}}
              color="error"
              startIcon={<Warning/>}
              onClick={() => navigate('/update-password/?from=/settings/account')}
              size={isDesktop ? 'small' : 'large'}
            >
              {t('changePassword')}
            </Button>
          </Box>
        </Box>

      </Stack>
    </>
  );
};

export { AccountComponent };
