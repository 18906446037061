import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

const DEFAULT_FILENAME = "troper_export"

interface Section {
  title: string;
  content: string;
}

interface DocDefinition {
  content: any,
  styles: any,
  pageMargins: [number, number, number, number],
  footer?: any,
  header?: any
}

const styles = {
  header: {
    fontSize: 12, // Changed to 12pt
    bold: true,
    margin: [0, 10, 0, 5]
  },
  subheader: {
    fontSize: 12, // Kept at 12pt
    italics: true,
    margin: [0, 5, 0, 5]
  },
  content: {
    fontSize: 11, // Changed to 11pt
    margin: [0, 0, 0, 10]
  },
  firstPageHeader: {
    fontSize: 20,
    bold: true,
    alignment: 'left',
    margin: [0, 20, 0, 10]
  },
  firstPageSubheader: {
    fontSize: 14,
    alignment: 'left',
    margin: [0, 0, 0, 10]
  },
  firstPageDate: {
    fontSize: 12,
    alignment: 'left',
    margin: [0, 0, 0, 10]
  },
  horizontalLine: {
    margin: [0, 20, 0, 20],
    canvas: [
      {
        type: 'line',
        x1: 0, y1: 0,
        x2: 451.28, y2: 0,
        lineWidth: 0.5,
        lineColor: 'rgb(215, 217, 224)'
      }
    ]
  }
};

class PDFGenerator {
  generatePDF(sections: Section[], fileName: string = DEFAULT_FILENAME): void {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    let markdownText: Array<{ text: string; style: string }> = [];

    sections.forEach(obj => {
      markdownText.push({ text: obj.title, style: 'header' });
      markdownText.push({ text: obj.content, style: 'content' });
    });

    const today = new Date();
    const formattedDate = today.toLocaleDateString('pt-BR', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });

    const firstPageContent = [
      { text: 'Troper', style: 'firstPageHeader' },
      { text: 'Informe de su consulta', style: 'firstPageSubheader' },
      { text: formattedDate, style: 'firstPageDate' },
      { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 451.28, y2: 0, lineWidth: 0.5, lineColor: 'rgb(215, 217, 224)' }] } // Added line below date
    ];

    var docDefinition: DocDefinition = {
      content: [
        ...firstPageContent,
        ...markdownText
      ],
      styles: styles,
      pageMargins: [72, 72, 72, 72], // 1 inch margins on all sides (72pt = 1 inch)
      header: function (currentPage: number) {
        return {
          canvas: [
            {
              type: 'line',
              x1: 0, y1: 0,
              x2: 595.28, y2: 0, // full width
              lineWidth: 6,
              lineColor: '#287AFF'
            }
          ],
          margin: [0, 3, 0, 0] // Adjust to stick to top
        };
      },
      footer: function (currentPage: number) {
        return {
          canvas: [
            {
              type: 'line',
              x1: 0, y1: 0,
              x2: 595.28, y2: 0, // full width
              lineWidth: 6,
              lineColor: '#287AFF'
            }
          ],
          margin: [0, 69, 0, 0] // Move line below bottom margin
        };
      },
      pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
        return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
      }
    };
    
    pdfMake.createPdf(docDefinition).download(fileName);
  }
}

export default PDFGenerator;
