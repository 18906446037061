export const DEFAULT_TRANSCRIPTION_LANGUAGE = "es"

interface Language {
    code: string,
    name: string
  }
  
export const transcriptionLanguages: Language[] = [
    {
      "code": "es",
      "name": "Español"
    },
    {
      "code": "ca",
      "name": "Català"
    },
    {
      "code": "en",
      "name": "English"
    },
    {
      "code": "pt",
      "name": "Português"
    },
    {
      "code": "de",
      "name": "Deutsch"
    },
    {
      "code": "fr",
      "name": "Français"
    },
    {
      "code": "it",
      "name": "Italiano"
    }
]

export const noteLanguages: Language[] = [
    {
      "code": "es",
      "name": "Español"
    },
    {
      "code": "pt",
      "name": "Português"
    },
    {
      "code": "ca",
      "name": "Català"
    },
    {
      "code": "en",
      "name": "English"
    }
]
  
