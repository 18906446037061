import { AvailableTemplates, UserInfo } from "@/types";

import { supabase } from '../api/supabaseClient';

function findDifferences(obj1: any, obj2: any): string[][] {
  const differences: string[][] = [];
  Object.keys(obj1).forEach(key => {
    if (obj1[key] !== obj2[key]) {
      if (typeof obj1[key] === 'object' && obj1[key] != null && typeof obj2[key] === 'object' && obj2[key] != null) {
        const deeperDiffs = findDifferences(obj1[key], obj2[key]);
        if (deeperDiffs.length > 0) {
          deeperDiffs.forEach(diff => differences.push([key, ...diff]));
        }
      } else if (obj1[key] !== obj2[key]) {
        differences.push([key]);
      }
    }
  });
    return differences;
  };

  export const fetchUserInfo = async () => {
    try {
      const userResponse = await supabase.from('users').select();
      const settingsResponse = await supabase.from('settings').select();
      const subscriptionsResponse = await supabase.from('subscriptions').select();
      const userMetrics = await supabase.from('user_metrics').select();
  
      const account = userResponse.data ? userResponse.data[0] : {};
      const settings = settingsResponse.data ? settingsResponse.data[0] : {};
      const subscription = subscriptionsResponse.data ? subscriptionsResponse.data[0] : {};
      const metrics = userMetrics.data ? userMetrics.data[0] : {};
  
      const data: UserInfo = { account, settings, subscription, metrics };
    
      return data;

    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  export const updateUserInfo = async (userInfo: any, originalUserInfo: any) => {
    const updatedUserInfoKeys = findDifferences(originalUserInfo, userInfo)
    updatedUserInfoKeys.forEach(async (key) => {
      let updateObject: any = {}
      let tableName = key[0] == 'account' ? 'users' : key[0];
      let filterColumnName = key[0] == 'account' ? 'id' : 'user_id';
      
      updateObject[key[1]] = userInfo[key[0]][key[1]]
      const { error } = await supabase
      .from(tableName)
      .update({ [key[1]]: userInfo[key[0]][key[1]] })
      .eq(filterColumnName, userInfo.account.id);
      if (error) {
        throw error;
      }
    });
    console.log('User info updated successfully');
  };
  
  export const getUserId = async () => {
    const user = await supabase.auth.getUser();
    return user?.data?.user?.id
  }
  
  export const getUserSpeciality = async () => {
    const userResponse = await supabase.from('users').select('speciality_id');
    return userResponse.data ? userResponse.data[0].speciality_id : 'general';
  }
  
  export const updatePreferredTemplate = async (templateId: string) => {
    const userId = await getUserId();
    if (templateId) {
      const { error } = await supabase
      .from('settings')
      .update({ preferred_template: templateId })
      .eq('user_id', userId);
      if (error) {
        throw error;
      }
      console.log('Preferred template updated successfully');
    }
  };
  
  export const updatePreferredLanguage = async (language: string) => {
    const userId = await getUserId();
    const { error } = await supabase
    .from('settings')
    .update({ preferred_language: language })
    .eq('user_id', userId);
    if (error) {
      throw error;
    }
    console.log('Preferred language updated successfully');
  }
  
  export const updatePreferredNoteLanguage = async (language: string) => {
    const userId = await getUserId();
    const { error } = await supabase
    .from('settings')
    .update({ preferred_note_language: language })
    .eq('user_id', userId);
    if (error) {
      throw error;
    }
    console.log('Preferred language updated successfully');
  }
  
  export const fetchAllTemplates = async () => {
    try {
      const response = await supabase.from('templates').select('id, name, template_id, order, speciality_id')
      if (response.data) {
        const data = response.data.map((template: any) => ({ "id": template.id, "template_id": template.template_id, "name": template.name, "order": template.order, "speciality_id": template.speciality_id }));
        return data;
      }
    } catch (error) {
      console.error('Error fetching all templates:', error);
    }
  };

  export const fetchUserTemplates = async () => {
    try {
      const response = await supabase
        .from('user_templates')
        .select('template_id, order, templates(id, name, template_id, speciality_id)')
        .order('order', { ascending: true });
      if (response.data && response.data.length > 0) {
        const data = response.data.map((template: any) => ({ "id": template.templates.id, "template_id": template.templates.template_id, "name": template.templates.name, "order": template.templates.order }));
        return data;
      } else {
        const userSpeciality = await getUserSpeciality();
        const response = await supabase
          .from('templates')
          .select('template_id, order, id, name, template_id, speciality_id')
          .eq('speciality_id', userSpeciality)
          .order('order', { ascending: true });
        if (response.data && response.data?.length > 0) {
          const data = response.data.map((template: any) => ({ "id": template.id, "template_id": template.template_id, "name": template.name, "order": template.order, "speciality_id": template.speciality_id }));
          return data;
        } else {
          const response = await supabase
          .from('templates')
          .select('template_id, order, id, name, template_id, speciality_id')
          .eq('speciality_id', 'general')
          .order('order', { ascending: true });
          if (response.data && response.data?.length > 0) {
            const data = response.data.map((template: any) => ({ "id": template.id, "template_id": template.template_id, "name": template.name, "order": template.order, "speciality_id": template.speciality_id }));
            return data;
          }
        }
      }
    } catch (error) {
      console.error('Error fetching user templates:', error);
    }
  };

  export const updateUserTemplates = async (templates: string[]): Promise<void> => {
    const userId = await getUserId();
    try {
      // Get the template IDs based on the provided template IDs
      const { data: templateData, error: fetchError } = await supabase
        .from('templates')
        .select('id')
        .in('template_id', templates);
  
      if (fetchError) {
        throw new Error(`Error fetching template IDs: ${fetchError.message}`);
      }
  
      const templateIds = templateData?.map(template => template.id) || [];
  
      // Start a transaction
      const { data: transactionData, error: transactionError } = await supabase
        .from('user_templates')
        .delete()
        .eq('user_id', userId);
  
      if (transactionError) {
        throw new Error(`Error deleting old user templates: ${transactionError.message}`);
      }
  
      // Insert new templates for the user
      const insertPromises = templateIds.map(templateId => {
        return supabase
          .from('user_templates')
          .insert([{ user_id: userId, template_id: templateId }]);
      });
  
      const insertResults = await Promise.all(insertPromises);
  
      for (const { error } of insertResults) {
        if (error) {
          throw new Error(`Error inserting new user template: ${error.message}`);
        }
      }
  
      console.log('User templates updated successfully');
    } catch (error) {
      console.error('Error updating user templates:', error);
    }
  }


  export const updateTemplatesOrder = async (templatesOrder: AvailableTemplates[]): Promise<void> => {
    try {
      const upsertPromises = templatesOrder.map(async ({ id }, index) => {
        // Check if the template exists
        const { data: existingTemplate, error: selectError } = await supabase
          .from('user_templates')
          .select('template_id')
          .eq('template_id', id)
          .limit(1);  // Limit to 1 to avoid multiple rows
  
        if (selectError) {
          throw new Error(`Error checking template existence: ${selectError.message}`);
        }
  
        if (existingTemplate && existingTemplate.length > 0) {
          // Update if the template exists
          const { error: updateError } = await supabase
            .from('user_templates')
            .update({ order: index })
            .eq('template_id', id);
  
          if (updateError) {
            throw new Error(`Error updating template order: ${updateError.message}`);
          }
        } else {
          // Insert if the template does not exist
          const userId = await getUserId();
          const { error: insertError } = await supabase
            .from('user_templates')
            .insert({ user_id: userId, template_id: id, order: index });
  
          if (insertError) {
            throw new Error(`Error inserting new template: ${insertError.message}`);
          }
        }
      });
  
      await Promise.all(upsertPromises);
  
      console.log('Templates order updated successfully');
    } catch (error) {
      console.error('Error updating templates order:', error);
    }
  };
  
  

