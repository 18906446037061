import { AvailableTemplates } from "../../../types";
import { fetchUserInfo, fetchUserTemplates, updatePreferredTemplate, updateTemplatesOrder } from "../../../services/userService";
import { DragHandle } from "@mui/icons-material";
import { Divider, ListItem, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import arrayMove from "array-move";
import React, { useEffect, useState } from "react";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import { useTranslation } from "react-i18next";

interface SortableTemplatesProps { 
  availableTeplates: AvailableTemplates[];
  setAvailableTeplates: (templates: AvailableTemplates[]) => void;
};

const SortableTemplates: React.FC<SortableTemplatesProps> = ({ 
  availableTeplates,
  setAvailableTeplates,
 }) => {
  const { t } = useTranslation(); 
  useEffect(() => {
      fetchUserTemplates().then((data: AvailableTemplates[] | undefined) => {setAvailableTeplates(data || [])});
  }, []);

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    const sortedArray = (templates: AvailableTemplates[]) => templates && arrayMove(templates, oldIndex, newIndex);
    const templates = sortedArray(availableTeplates);
    setAvailableTeplates(templates);
    updateTemplatesOrder(templates)
  };

  const handleListItemIconClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <SortableList
      onSortEnd={onSortEnd}
      className="list"
      draggedItemClassName="dragged"
      lockAxis="y"
    >
      {availableTeplates?.map((item, index) => (
        <SortableItem key={item.template_id}>
          <ListItem 
            key={index}
            sx={{borderBottom: availableTeplates.length == index + 1  ? 0 : 1, borderColor: "divider"}}
          >
            <ListItemText sx={{ whiteSpace: "normal" }}>  
              {t(`template_names.${item.template_id}`,item.name)}
            </ListItemText>
            <SortableKnob>
              <ListItemIcon sx={{ cursor: "grab" }} onClick={handleListItemIconClick}>
                <DragHandle />
              </ListItemIcon>  
            </SortableKnob>
          </ListItem>
        </SortableItem>
      ))}
    </SortableList>
  );
}

export default SortableTemplates;
