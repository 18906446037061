import { UserInfo } from '../../../types';
import { CircularProgress, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent } from '@mui/material';
import React, { useEffect } from 'react';
import { transcriptionLanguages } from '../../../transcriptionConfig';
import { updatePreferredLanguage } from '../../../services/userService';
import { useTranslation } from 'react-i18next';


interface SelectTranscriptionLanguageProps { 
  userInfo: UserInfo; 
  setUserInfo: (userInfo: UserInfo) => void;
  setApplicationLanguage: (language: string) => void;
  fullWidth?: boolean;
};

const SelectApplicationLanguage: React.FC<SelectTranscriptionLanguageProps> = ({ 
  userInfo,
  setUserInfo,
  setApplicationLanguage,
  fullWidth,
}) => {
  const { i18n } = useTranslation();
  
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    const target = event.target as HTMLInputElement | HTMLSelectElement;
    const { name, value } = target;
    let user = { ...userInfo };
    user.settings[name as keyof typeof user.settings] = value;
    setUserInfo(user);
  }

  useEffect(() => {
    i18n.changeLanguage(userInfo.settings.application_language || i18n.resolvedLanguage);
  }, [userInfo.settings.application_language]);


  return (
    <FormControl>
      <RadioGroup
        value={userInfo.settings?.application_language || i18n.resolvedLanguage}
        name="application_language"
        onChange={handleChange}
      >
        <FormControlLabel value="es" control={<Radio />} label="Español" />
        <FormControlLabel value="pt" control={<Radio />} label="Português" />
        <FormControlLabel value="ca" control={<Radio />} label="Catalan" />
        <FormControlLabel value="en" control={<Radio />} label="English" />
      </RadioGroup>
    </FormControl>
    );
  };

export { SelectApplicationLanguage };
