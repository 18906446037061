import SettingsIcon from '@mui/icons-material/Settings';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import * as React from 'react';
import { SettingsPage } from './SettingsPage';
import { useTranslation } from 'react-i18next';

function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

interface BreadcrumbComponentProps {
  selectedSettingsPage: SettingsPage
};
    
  
  const BreadcrumbComponent: React.FC<BreadcrumbComponentProps> = ({ 
    selectedSettingsPage
  }) => {
    const { t } = useTranslation();
  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb" sx={{mt: 2}}>
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="/"
        >
          <SettingsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          {t('settings')}
        </Link>
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="/material-ui/getting-started/installation/"
        >
          {React.createElement(selectedSettingsPage.icon)}
          {t('settingsPageTitle.'+selectedSettingsPage.id)}
        </Link>
        {/* <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Breadcrumb
        </Typography> */}
      </Breadcrumbs>
    </div>
  );
}

export default BreadcrumbComponent;